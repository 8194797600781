export const PricingData = [
  {
    title: "Classes 1st - 5th",
    color: "#fce4ec",
    price1: "5999",
    price2: "36K",
    offer: [
      "  Animated Videos",
      "Video Quizzes",
      "Notes",
      "Chapter Quizzes",
      "Score Cards",
      "Worksheets",
      " Worksheet Solutions",
      "Ask to Teacher",
    ],
  },
  {
    title: "Classes 6th - 8th",
    price1: "7999",
    price2: "54K",
    color: "#e8f5e9",
    offer: [
      "  Animated Videos",
      "Video Quizzes",
      "Notes",
      "Chapter Quizzes",
      "Score Cards",
      "Worksheets",
      " Worksheet Solutions",
      "Ask to Teacher",
    ],
  },
  {
    title: "Classes 9th - 12th",
    price1: "8999",
    price2: "36K",
    color: "#f7f7d5",
    offer: [
      "Recorded classes",
      "Video Quizzes",
      " Notes",
      "Chapter Quizzes",
      "Assertion Reasoning / Case Study",
      "Score Cards",
      "Worksheets",
      "Worksheet Solutions",
      "Ask to Teacher",
      "Live Classes",
    ],
  },
  // {
  //   title: "Classes 11th - 12th",
  //   price1: "36K",
  //   price2: "48K",
  //   color:"#ffe7d7",
  //   offer: [
  //     "Recorded classes",
  //     "Video Quizzes",
  //     "Notes",
  //     "Chapter Quizzes",
  //     "Assertion Reasoning / Case Study",
  //     "Score Cards",
  //     "Worksheets",
  //     "Worksheet Solutions",
  //     "Ask to Teacher",
  //     "Live Classes"
  //   ],
  // },
  // {
  //   title: "Robotics",
  //   price1: "6K",
  //   price2: "",
  //   color:"#fff8e1",
  //   offer: [
  //     "Animated Videos",
  //     "Experiment Kit - Robotics",
  //     " Virtual and Practical Lab",
  //     "Ask to Teacher",
  //   ],
  // },
];
