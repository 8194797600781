import React, { useState, createContext } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./Firebase/FireBase";
import { useEffect } from "react";

export const UserContext = React.createContext();

const ImageContextProvider = ({ children }) => {
  const [images, setImages] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [formType, setFormType] = useState({
    demo:"",
    franchise:""
  });
  
  

  const fetchImage = async () => {
    const q = query(collection(db, "festiveTheme"));
    const snapshot = await getDocs(q);
    const imageData = snapshot.docs.map((doc) => doc.data());
    console.log("data fetch", imageData);
    setImages(imageData);
    
    for(let i = 0 ; i < imageData.length;i++){
      console.log("in loop" ,imageData[i].type,imageData[i].imageUrl)
        setFormType( p => { return {...p,[imageData[i].type] : imageData[i].imageUrl } })
    }
  };

  const loadImage = async (type) => {
    const url = await fetchImage(type);
    setImageUrl(url);
  };

  const contextValue = {
    imageUrl,
   formType,
    loadImage,
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <>
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
    </>
  );
};

export default ImageContextProvider;
