export const LearningData = [
  {
    heading: "Build up Your Level",
    span: "Classes 1st - 5th",
    headContent:
      "The journey of learning begins here. Kids start developing basic skills and understand new concepts in various subjects.",
    SubHeading1: "Subjects we offer:",
    list1: ["English", "Maths", "EVS", "Science", "SST"],
    subHeading2: "Key Highlights",
    list2: [
      "Easy and interactive content in both English and Hindi Language.",
      "Explore new words and expand vocabulary",
      "Learn all subjects with Self-Assessment Worksheets, Test Series and more.",
      "Get Automated Progress Reports.",
      "Animated Videos",
    ],
    logo: require("../Assests/webimg/cl_1.png"),
    maxlimit: 4,
    minlimit: 0,
  },
  {
    heading: "Enhance your skills",
    span: " Classes 6th - 8th",
    headContent:
      "Students now reach the level where their basic skills are developed, and they learn familiar topics in various subjects with a practical approach.",
    SubHeading1: "Subjects we offer:",
    list1: ["English", "Maths", "EVS", "Science", "SST"],
    subHeading2: "Key Highlights",
    list2: [
      "Easy and interactive content in both English and Hindi Language.",
      "Explore new words and expand vocabulary.",
      "Learn all subjects with Self-Assessment Worksheets, Test Series and more.",
      "Get Automated Progress Reports.",
      "Animated Videos",
    ],
    logo: require("../Assests/webimg/cl_6.png"),
    maxlimit: 7,
    minlimit: 5,
  },
  {
    heading: "Advance your subjects concepts",
    span: " Classes 9th - 10th",
    headContent:
      "This level is intended to accelerate the primary and secondary conceptual understanding of the learners of each subject to prepare them for higher studies. Here they will begin to effectively understand where their core interest in the subject lies.",
    SubHeading1: "Subjects we offer:",
    list1: ["Maths", "Science", "English"],
    subHeading2: "Key Highlights",
    list2: [
      "Conceptual PPT & Videos",
      "Remarkable Notes",
      "Evaluation Quizzes",
      "Automated Progress Reports",
      "Doubt Sessions",
    ],
    logo: require("../Assests/webimg/cl_8.png"),
    maxlimit: 9,
    minlimit: 8,
  },

  {
    heading: "Master your Specialization",
    span: " Classes 11th - 12th",
    headContent:
      "At this level, students focus on their potential to choose their career path by opting subjects of their choice and attain specialisation in them.",
    SubHeading1: "Subjects we offer:",
    list1: ["Maths", "Physics", "Chemistry", "Biology"],
    subHeading2: "Key Highlights",
    list2: [
      "Conceptual PPT & Videos",
      "Remarkable Notes",
      "Evaluation Quizzes",
      "Automated Progress Reports",
      "Doubt Sessions",
    ],
    logo: require("../Assests/webimg/cl_9.png"),
    maxlimit: 11,
    minlimit: 10,
  },
  // {
  //   heading: "Win the World with ",
  //   span: " Robotics",
  //   headContent:
  //     "   Students can create a world of their imagination and creativity by using the unique concepts designed by Iskoolz for them.",
  //   SubHeading1: "Subjects we offer:",
  //   list1: ["Robotics"],
  //   subHeading2: "Key Highlights",
  //   list2: [
  //     " Classroom Management tools",
  //     "Progress reports",
  //     "Automated Self-Assessment evaluation",
  //     "Test Series",
  //     "Animated videos",
  //   ],
  //   logo:require("../Assests/webimg/robotics.png"),
  // },
];
