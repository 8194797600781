import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./Context";
import ImageContextProvider from "./imageContext";
// import './custom-bootstrap.css'; // import the custom Bootstrap CSS

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <ImageContextProvider>
      <App />
      </ImageContextProvider>
    </ContextProvider>
  </React.StrictMode>
);
