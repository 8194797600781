import React, { useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { Pagination } from 'swiper';
import { Autoplay, Mousewheel, } from "swiper";
// Import Swiper styles

import "../../Styles/Dashboard.css";
import { Context } from "../../Context";
// import required modules


function SwiperDash() {

  const { isMobile, handleResize } = useContext(Context);

  useEffect(() => {
    handleResize()
    //  console.log(isMobile) 
  }, [isMobile])
  return (
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
    <div className="carousel-inner">
      <div className="carousel-item active">
      
        <img src={
              require("../../Assests/images/banner1.png")
            }className="d-block w-100" alt="..."/>
      </div>
      <div className="carousel-item ">
        <img src={
              require("../../Assests/images/banner2.png")
            } className="d-block w-100" alt="..."/>
      </div>
      <div className="carousel-item ">
        <img src={
              require("../../Assests/images/banner3.png")
            } className="d-block w-100" alt="..."/>
      </div>
    </div>
  
  </div>
   
  );
}

export default SwiperDash;
 