import React, { useState, useEffect } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import "../Styles/Header.css";
import TemporaryDrawer from "../Common/TemporaryDrawer";
import FormModal from "../Common/FormModal";
import { HashLink } from "react-router-hash-link";
import { Button } from "@mui/material";
import { MdPhone } from "react-icons/md";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function Header() {
  const [show, setShow] = useState(true);
  const [color, setColor] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    // const login = localStorage.getItem("@secure.b.login");
    // setLogin(login);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", updateHeight);
    return () => window.removeEventListener("scroll", updateHeight);
  }, []);

  function updateHeight() {
    let ChangingColor = 100;
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > ChangingColor) {
      setColor(true);
    } else {
      setColor(false);
    }
  }

  return (
    <div
      className="fixed-top"
      style={{ background: color ? "var(--white50)" : "var(--muteWhite)" }}
    >
      <div className="px-2 py-2">
        <header className="d-flex justify-content-between  align-items-center">
          <HashLink to="#home" className="item">
            <img
              src={require("../Assests/images/iskoolz_logo.png")}
              width={70}
              alt="iskoolz-icon"
            />
          </HashLink>
          <div
            className="menu-items d-sm-none d-md-block d-lg-block d-xxl-block"
            id="navbarSupportedContent"
          >
            <ul className="nav gap-3  align-items-center">
              <li className="nav-item">
                <HashLink to="#home" className="item">
                  Home
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink smooth to="#about" className="item">
                  About
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink smooth to="#pricing" className="item">
                  Pricing
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink to="#contact-home" className="item">
                  Contact
                </HashLink>
              </li>
              <li className="nav-item">
                <NavLink to="/franchise" className="item">
                  Get a Franchise
                </NavLink>
              </li>
              {login ? (
                <li className="nav-item">
                  <NavLink to="/dashboard" className="item">
                    Profile
                  </NavLink>
                </li>
              ) : (
                <li className="">
                  <Button
                    variant="contained"
                    type="submit"
                    // onClick={() => setModalShow(true)}
                    // disabled
                    sx={{
                      borderRadius: "25px",
                      textTransform: "none",
                      fontFamily: "var(--para-font)",
                      fontSize: "16px",
                      width: "90px",

                      height: "40px",
                      background: " rgb(249, 234, 237)",
                      fontWeight: "bold",
                      border: "2px solid rgb(252, 72, 102);",
                      color: "black",
                      mt: 0,
                      "  &:hover": {
                        backgroundColor: "rgb(249, 234, 237, 0.861)",
                      },
                    }}
                  >
                    Login
                  </Button>
                </li>
              )}
            </ul>

            <FormModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>

          <div
            className="hamburger-menu d-md-none d-lg-none d-xxl-none"
            onClick={() => setShow(!show)}
          >
            <TemporaryDrawer />
          </div>
        </header>
      </div>
    </div>
  );
}

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "var(--theme-color)",
    fontSize: "15px",
  },
}));

const handleCall = () => {
  window.location.href = "tel://+919873008999";
};
export default Header;

export const FranchiseHeader = () => {
  const [show, setShow] = useState(true);
  const [color, setColor] = useState(false);
  const params = useParams();

  useEffect(() => {
    window.addEventListener("scroll", updateHeight);
    return () => window.removeEventListener("scroll", updateHeight);
  });

  function updateHeight() {
    let ChangingColor = 470;
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > ChangingColor) {
      setColor(true);
    } else {
      setColor(false);
    }
  }
  return (
    <div
      className="fixed-top "
      style={{ background: color ? "var(--white50)" : "var(--muteWhite)" }}
    >
      <div className="container ">
        <header className="d-flex justify-content-between align-items-center ">
          <HashLink to="/" className="item">
            <img
              src={require("../Assests/images/iskoolz_logo.png")}
              width={70}
              alt="iskoolz-icon"
            />
          </HashLink>

          <div
            className="menu-items d-sm-none d-md-block d-lg-block d-xxl-block"
            id="navbarSupportedContent"
          >
            <ul className="nav gap-3">
              <li className="nav-item">
                <a href="#f-about" className="item">
                  About
                </a>
              </li>

              <li className="nav-item">
                <a href="#f-apply" className="item">
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a href="#f-contact" className="item">
                  Apply for
                </a>
              </li>
              <li className="nav-item">
                {params.type ? (
                  <NavLink to="/franchise" className="item">
                    Switch to Franchise
                  </NavLink>
                ) : (
                  <NavLink to="/franchise/Sub" className="item">
                    Switch to Subfranchise
                  </NavLink>
                )}
              </li>
            </ul>
          </div>

          <div className="d-none  d-md-inline-block">
            <BootstrapTooltip title="+91-9873008999">
              <Avatar
                onClick={handleCall}
                sx={{
                  bgcolor: "var(--theme-color)",
                  width: 28,
                  height: 28,
                  p: 0.7,
                  cursor: "pointer",
                }}
              >
                {" "}
                <MdPhone />
              </Avatar>
            </BootstrapTooltip>
          </div>

          <div
            className="hamburger-menu d-md-none d-lg-none d-xxl-none"
            onClick={() => setShow(!show)}
          >
            <TemporaryDrawer />
          </div>
        </header>
      </div>
    </div>
  );
};
