import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth"
import { getDatabase, ref, push, set } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCuAj3b3JX79lxUWXJJExsjCQ5AbB6ZobQ",
  authDomain: "iskoolz-test.firebaseapp.com",
  databaseURL: "https://iskoolz-test-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "iskoolz-test",
  storageBucket: "iskoolz-test.appspot.com",
  messagingSenderId: "253083185482",
  appId: "1:253083185482:web:05d5ffac68c6a08e5611b3",
  measurementId: "G-D3M4RWS3PC"
};
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth =getAuth(app)
export const db = getFirestore(app)
export const database = getDatabase(app)

