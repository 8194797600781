import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "@mui/material/Button";
import { swiperdata } from "../Services/SwiperData";
import { ParaGraph } from "../Styles/Style";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Styles/swiper.css";
import AddReview from "./AddReview";

// import required modules
import { Navigation } from "swiper";
import { ParaGrey } from "./../Styles/Style";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function CommentSwiper() {
  const matchesQVGA = useMediaQuery("(max-width:310px)");
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Swiper
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        style={{ border: "0px solid red" }}
      >
        {swiperdata.map((item, index) => {
          return (
            <SwiperSlide
              className="mb-3"
              key={index}
              sx={{ border: "0px solid red" }}
            >
              <div className="card">
                <div className="card-body">
                  <ParaGraph className="fs-5">{item.Content}</ParaGraph>

                  <div
                    className={`float-end ${
                      matchesQVGA ? "m-0" : "mt-3 mx-5 mb-3"
                    }`}
                    style={{ lineHeight: "5px" }}
                  >
                    <ParaGrey className="text-dark text-end">
                      {item.name}
                    </ParaGrey>
                    <ParaGrey className="text-end">{item.city}</ParaGrey>
                    <Button
                      onClick={(e) => setModalShow(true)}
                      style={{
                        borderRadius: "35px",
                        cursor: "pointer",
                        fontSize: "15px",
                        fontFamily: "var(--heading-font)",
                        color: "var(--theme-color)",
                        fontWeight: "bold",
                        textTransform: "none",
                        float: "right",
                      }}
                    >
                      Add a review
                    </Button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}

        <AddReview show={modalShow} onHide={() => setModalShow(false)} />
      </Swiper>
    </>
  );
}
