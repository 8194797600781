import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { MiniHeading, ParaGrey } from "./../Styles/Style";
import { TextField, Button } from "@mui/material";
import { useAuth } from "../Context";
import { useNavigate } from "react-router-dom";
import { theme } from "../Styles/Theme";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "../Styles/Theme";
import { toast } from "react-toastify";

function FormModal(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setUpRecaptcha } = useAuth();
  const [otp, setOtp] = useState("");
  const [confirmObj, setConfirmObj] = useState("");
  const [number, setNumber] = useState("");
  const [error, setError] = useState(""); // Mobile number or otp error state
  const [show, setShow] = useState(false); // state for show is true then showing otp page
  const [disable, setDisabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const { loading, setloading, Spinner } = useAuth();
  const [userUID, setUserUID] = useState("");

  useEffect(() => {
    secureLocalStorage.getItem("login");
    localStorage.getItem("userUID");
  }, []);

  // const handleChangeNum = (e) => {
  //   // const newPhoneNumber = `+91 ${e.target.value.substr(4)}`;
  //   setNumber(e.target.value);
  // };

  // const valiadate = (number) => {
  //   // console.log("validation function call");
  //   // Function for mobile number verification
  //   const pattern = /^\d{10}$/;

  //   if (!(pattern.test(number) === true)) {
  //     return setError(true);
  //   } else  {
  //     return setError(false);
  //   }
  // };

  // const handleSubmit = (e) => {
  //      e.preventDefault();
  //      setIsSubmit(true);
  //      valiadate(number);

  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    setDisabled(true);
    if (!number || !/^\d{10}$/.test(number)) {
      setError(true);
      setDisabled(false);
      return;
    } else {
      setError(false);
      try {
        const phoneNumber = "+91" + number;
        const res = await setUpRecaptcha(phoneNumber);
        setConfirmObj(res);
        setDisabled(false);
        setShow(true);
        setError("");
      } catch (err) {
        if (err.code == "auth/too-many-requests") {
          toast.error("Too many requests. Try again after few hours!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setError("Too many requests. Try again after few hours!");
          setNumber("");
          setDisabled(false);
        } else {
          setError("Please enter a valid number!");
          setNumber("");
          setDisabled(false);
        }
        console.error(error);
      }
    }
  };

  // useEffect(() => {
  //   const sendData = async () => {
  //     if (error === false && isSubmit === true) {
  //       setDisabled(true);
  //       setloading(true);

  //       try {
  //         const phoneNumber = "+91"+number;
  //         const res = await setUpRecaptcha(phoneNumber);
  //         setConfirmObj(res);
  //         setDisabled(false);
  //         setShow(true);
  //         setloading(false);
  //       } catch (err) {
  //         setError(err);
  //         setDisabled(false);
  //         setloading(false);
  //       }
  //     }
  //   };
  //   sendData();

  // }, [error]);

  // function for verify otp:

  const handleChangeOTP = (e) => {
    setOtp(e.target.value);
  };
  const verifyOtp = async (e) => {
    e.preventDefault();
    setloading(true);
    if (otp === "" || otp === null) {
      setloading(false);
      return setError("Please enter the correct OTP");
    }

    try {
      const res = await confirmObj.confirm(otp);
      console.log("res============>>>>>>>>>", res);
      secureLocalStorage.setItem("login", true);

      localStorage.setItem("ReactUID", res.user.uid);
      setloading(false);
      navigate("/dashboard");
      setUserUID(res.user.uid);
      localStorage.setItem("userUID", res.user.uid);
    } catch (err) {
      setError("Enter the correct OTP");
      setloading(false);
    }
  };

  //  cross button function for modal close or (state) setShow is false:-->
  let close = props.onHide;
  const close_fun = () => {
    close();
    setNumber("");
    setShow(false);
    setError("");
    setDisabled(false);
    setIsSubmit(false);

    secureLocalStorage.removeItem("_grecaptcha");
  };

  return (
    <ThemeProvider theme={theme}>
      {loading ? Spinner : ""}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ padding: "5px" }}
      >
        {show ? (
          <>
            {/* otp display of modal */}

            <div className="p-2 m-0 float-end">
              <i
                className="fa-sharp fa-solid fa-xmark"
                style={{ float: "right", cursor: "pointer" }}
                onClick={close_fun}
              ></i>
            </div>

            <div className="row p-4 pt-0">
              <div className="col-md-6 col-sm-6  col-12 my-md-auto text-center">
                {" "}
                <img
                  src={require("../Assests/images/iskoolz_logo.png")}
                  width={190}
                  className="img-fluid"
                  alt="iskoolz-icon"
                />
              </div>
              {/* Right content */}
              <div className="col-md-6 col-sm-6  col-12 text-center">
                <br />
                <h2 className="text-center fs-3">
                  Enroll now to learn LIVE online from india's best teachers
                </h2>
                <br />
                <div>
                  <TextField
                    inputProps={{ maxLength: 6 }}
                    id="filled-basic"
                    fullWidth
                    value={otp}
                    type="text"
                    color="primary"
                    label="Enter OTP"
                    autoFocus={true}
                    variant="filled"
                    placeholder="Enter OTP"
                    onChange={handleChangeOTP}
                    helperText={error}
                    error={error ? true : false}
                  />
                </div>

                <br />
                <div>
                  {loading === true ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <Button
                      fullWidth
                      type="submit"
                      onClick={verifyOtp}
                      variant="contained"
                      style={{
                        borderRadius: "35px",
                        backgroundColor: "var(--theme-color)",
                        fontSize: "10px",
                        fontFamily: "var(--heading-font)",
                      }}
                    >
                      Submit otp
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/*home page modal */}
            <div className="p-2 m-0  float-end">
              {/* Cross button */}

              <i
                class="fa-sharp fa-solid fa-xmark"
                style={{ float: "right", cursor: "pointer" }}
                onClick={close_fun}
              ></i>
            </div>
            <div className="row p-4 pt-0">
              <div className="col-md-6 col-sm-6  col-12 m-auto text-center ">
                <img
                  src={require("../Assests/images/iskoolz_logo.png")}
                  width={190}
                  className="img-fluid"
                  alt="iskoolz-icon"
                />
              </div>
              <div className="col-md-6 col-sm-6  col-12 text-center">
                <br />
                <MiniHeading className="text-center">
                  Enroll now to learn LIVE online from india's best teachers
                </MiniHeading>
                <br />
                <div>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="filled-basic"
                      fullWidth
                      autoComplete="off"
                      value={number}
                      type="number"
                      className={classes.input}
                      color="primary"
                      label="Enter Phone number"
                      variant="filled"
                      autoFocus={true}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      inputProps={{
                        maxLength: 10,
                      }}
                      onChange={(e) => setNumber(e.target.value)}
                      helperText={
                        error ? "Please enter a valid phone number" : ""
                      }
                      error={error}
                    />
                    {isSubmit && (
                      <div id="recaptcha-container" className="col-7 "></div>
                    )}
                    <br />
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      disabled={disable}
                      style={{
                        borderRadius: "35px",
                        backgroundColor: "var(--theme-color)",
                        fontSize: "15px",
                        fontFamily: "var(--heading-font)",
                      }}
                    >
                      Next
                    </Button>
                  </form>
                </div>

                <br />
                <div>
                  <ParaGrey className="text-center my-2  ">
                    By continuing ,you agree the{" "}
                    <Link
                      to="/terms"
                      style={{
                        color: "var( --theme-color)",
                        textDecoration: "none",
                      }}
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/privacy"
                      style={{
                        color: "var( --theme-color)",
                        textDecoration: "none",
                      }}
                    >
                      Privacy policy
                    </Link>
                  </ParaGrey>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    </ThemeProvider>
  );
}

export default FormModal;
