import React from 'react'
// import Corousel1 from '../Common/Corousel1'
import MainForm from '../Common/MainForm'


function Banner() {
  return (
    <div>
      {/* <Corousel1/> */}
      <MainForm />
    </div>
  )
}

export default Banner
