import styled from "styled-components";

// For main heading
export const Heading = styled.h1`
  font-weight: bold;
  color: black;
  font-size: 37px;
  font-family: var(--para-heading);
  @media (max-width: 500px) {
    font-size: 30px;
  
  }
`;

// for color black Paragraph with font 
export const ParaGraph = styled.p`
  color: black;
  font-size: 16px;
  font-family: var(--para-font);
  @media (max-width: 500px) {
    font-size: 15px;

  }
`;
// For grey Paragraph
export const ParaGrey = styled.p`
  color: var(--para-color);
  letter-spacing: 1px;
  font-size: 15px;
  text-align: left;
  font-family: var(--para-font);
  @media (max-width: 500px) {
    font-size: 14px;

  }
`;


// For small heading
export const MiniHeading = styled.h5`
  font-size: 15px;
  font-weight: 650;
  font-family: var(--para-font);
  @media (max-width: 500px) {
   
  }
`;
// For black heading medium size
export const SubHeading = styled.h5`
  font-size: 20px;
  font-weight: bold;
  font-family: var(--para-font);
  @media (max-width: 600px) {
     ${'' /* font-size: 18px;   */}
  }
`;

// For pink heading medium size
export const HeadingPink = styled.span`
  color: var(--theme-color);
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  font-family: var(--para-font);
  @media ( max-width: 700px) {
   font-size:18px
  }
`;

export const StyleUl = styled.ul`
    line-height:2;
   font-family: var(--para-font);
   padding-left:25px;
   color:"black";
 
   
 
`;
export const Styleli = styled.li`
  color: black;
  font-size: 15px;
  text-decoration:none;
  font-family: var(--para-font);
  list-style-position: outside;
 

 
`;

export const Styleli2 = styled.li`
  color: black;
  font-size: 15px;
  list-style:none;
  text-decoration:none;
  font-family: var(--para-font);

 

  

`;



