import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";

export function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container justify-content-center d-flex rounded-1 ">
        <iframe
          width="800"
          height="415"
          src="https://www.youtube.com/embed/INt8FQZBg0w"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="p-2"
        >
        </iframe>

        <div style={{ position: "relative", right: "10px", cursor: "pointer" }}>
          <AiOutlineClose onClick={props.onHide} className="mr-3" />
        </div>
      </div>
    </Modal>
  );
}
