 export const swiperdata=[
    {
        Content:"My kids learning skills has developed so far with Iskoolz content that he can now write and speak confidently.",
        name:"Manoj Kumar",
        city:"New Delhi, Delhi (NCT)"
    },
    {
        Content:" I always faced issues with my son's Education but sincerely with Iskoolz now he understands everything.",
        name:"Neetu",
        city:"Gurgaon, Haryana"
    },
    {
        Content:"I always used to fear Mathematics, but with iskoolz conceptual videos now i have crystal clear.",
        name:"Rohit Yadav",
        city:"Gurgaon, Haryana"
    },
    {
        Content:"Great platform to enhance your children performance Connecbud provides best teachers to the clients.",
        name:"Deepak",
        city:"Rohtak, Haryana"
    },
    {
        Content:"Best platform for online learning where kids can learn various subjects like coding, mathematics , science etc.",
        name:"Tanu",
        city:"Noida(NCT)"
    },
 ]

