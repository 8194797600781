import React, { useEffect, useState, useMemo, useContext } from "react";
import { FranchiseHeader } from "../../Components/Header";
import { Heading, ParaGraph, SubHeading } from "../../Styles/Style";
import { ParaGrey } from "../../Styles/Style";
import Card from "../../Common/Card";
import { FranchiseData } from "../../Services/FranchiseData";
import { useAuth } from "../../Context";
import { ToastContainer, toast } from "react-toastify";
import { AssignmentTurnedIn } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import {
  TextField,
  Button,
  InputLabel,
  Select,
  Tooltip,
  MenuItem,
  FormControl,
  ThemeProvider,
  Stack,
} from "@mui/material";

import { FiCopy } from "react-icons/fi";
import Snackbar from "@mui/material/Snackbar";
import { useStyles } from "../../Styles/Theme";
import "../../Styles/Franchise.css";
import { theme } from "../../Styles/Theme";
import { useParams } from "react-router-dom";
import { getFranchise, getSubFranchise, checkFranchise } from "../allApi";
import secureLocalStorage from "react-secure-storage";
import dataJson from "../../Services/CitiesData.json";
import Footer from "../../Components/Footer";
import Modal from "@mui/material/Modal";
import { UserContext } from "../../imageContext";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  backgroundColor: "white",
  border: "none",
  borderRadius: "8px",
  textAlign: "center",
  padding: "10px",
};

function Franchise() {
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
    phoneSnack: false,
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  //Snackbar section

  const [districts, setDistricts] = useState([]);

  const params = useParams();

  const classes = useStyles();

  const { formType } = useContext(UserContext);

  const { isMobile, handleResize, Spinner, loading, setloading } = useAuth();
  const [condition, setCondition] = useState(false);
  const [isFilled, setIsFilled] = useState(true);
  const [filledType, setFilledType] = useState("");

  useEffect(() => {
    handleResize();
    secureLocalStorage.setItem("type", "franchise");
    const userUID = localStorage.getItem("ReactUID");
    const checkFrn = async () => {
      const resp = await checkFranchise({
        uid: localStorage.getItem("ReactUID"),
      });
      console.log("checker", resp);
      setIsFilled(resp.status);
      setFilledType(resp.formType);
    };
    if (userUID) {
      checkFrn();
    }
  }, []);

  // clear states while switch the page
  useEffect(() => {
    setFormValues(initialValues);
  }, [params]);

  useMemo(() => {
    if (params.type === "Sub") {
      setCondition(true);
    } else {
      setCondition(false);
    }
  }, [condition]);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    gender: "",
    pincode: "",
    address: "",
    district: "",
    city: "",
    code: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [confirmObj, setConfirmObj] = useState("");
  const { setUpRecaptcha } = useAuth();
  const [Test, setTest] = useState(false);

  const [text] = useState("WCISKOOLZ");

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      //alert("Text copied to clipboard!");
      toast.success("Copied to clipboard", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "linear-gradient(to right, #00b09b, #96c93d)",
          color: "white",
          fontSize: "16px",
        },
        toastClassName: "custom-toast",
      });
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  // Function for getting a value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    dataJson.states.filter((x) => {
      if (x.state === e.target.value) {
        setDistricts(x.districts);
      }
    });
  };

  const [otpDisplay, setOtpDisplay] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");

  const verifyOtp = async (e) => {
    e.preventDefault();
    setloading(true);
    if (!otp) {
      console.log("eeeeeerrrrrooooorrrrrrr");
      // setErrors((prevErr) => ({
      //   ...prevErr,
      //   otp: "Please enter the correct OTP",
      // }));
      setloading(false);
      return;
    }

    try {
      const response = await confirmObj.confirm(otp);
      secureLocalStorage.setItem("login", true);
      localStorage.setItem("ReactUID", response.user.uid);

      await fetchData(response.user.uid);
      setOtp("");
      setOtpDisplay(false);

      // setSuccess(true);
    } catch (err) {
      if (err.code == "auth/invalid-verification-code") {
        toast.error("Wrong Otp!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (err.code == "auth/code-expired") {
        toast.error("Otp Expired!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false); // for Submission
        setDisable(false);
        setOtpDisplay(false);
        setFormValues({ ...formValues, phone: "" });
      }

      setOtp("");
      setloading(false);
    }
  };
  // Form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const er = validateForm(formValues);

    if (Object.keys(er).length > 0) {
      setState({ open: true, vertical: "bottom", horizontal: "left" });
    }

    setFormErrors(er);

    if (Object.keys(er).length === 0) {
      setIsSubmit(true);
    }
  };

  const data = formValues;

  const fetchData = async (UID) => {
    setloading(true);

    if (condition) {
      const subFranchiseData = await getSubFranchise({
        ...formValues,
        uid: UID,
      });
      if (subFranchiseData.status == 200) {
        setIsSubmit(false);
        console.log("franchise api call");
        setFormValues(initialValues);
        toast.success("Sub-Franchise is booked.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastClassName: "custom-toast",
        });
        setIsOtpVerified(false);
        setOtp("");
        setOtpDisplay(false);
        setIsFilled(false);
        setFilledType("Sub Franchise");
      } else if (subFranchiseData.status == 406) {
        toast.error("Sub-Franchise is already booked on this number!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastClassName: "custom-toast",
        });
        setIsSubmit(false);
        setFormValues({ ...formValues, phone: "" });
        setFormErrors({
          ...formErrors,
          phone: "Sub-Franchise is already booked on this number.",
        });
        setIsOtpVerified(false);
        setOtp("");
        setOtpDisplay(false);
      } else {
        setIsSubmit(false);
        console.log("Data not submitted");
        setIsOtpVerified(false);
        setOtp("");
        setOtpDisplay(false);
        toast.error("Something Went Wrong. Please try again!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastClassName: "custom-toast",
        });
      }

      setDisable(false);
      setloading(false);
    } else {
      const frenchiseData = await getFranchise({
        ...data,
        uid: UID,
      });
      if (frenchiseData.status == 200) {
        setIsSubmit(false);
        console.log("franchise api call");
        setFormValues(initialValues);
        toast.success("Franchise is booked.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastClassName: "custom-toast",
        });
        setIsOtpVerified(false);
        setOtp("");
        setOtpDisplay(false);
        setIsFilled(false);
        setFilledType("Franchise");
      } else if (frenchiseData.status == 406) {
        toast.error("Franchise already booked on this number!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastClassName: "custom-toast",
        });
        setIsSubmit(false);
        setFormValues({ ...formValues, phone: "" });
        setFormErrors({
          ...formErrors,
          phone: "Franchise already booked on this number!",
        });
        setIsOtpVerified(false);
        setOtp("");
        setOtpDisplay(false);
      } else {
        setIsSubmit(false);
        console.log("Data not submitted");
        setIsOtpVerified(false);
        setOtp("");
        setOtpDisplay(false);
        toast.error("Something Went Wrong. Please try again!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastClassName: "custom-toast",
        });
      }

      setDisable(false);
      setloading(false);
    }
  };

  // validations of  fields

  const validateForm = (values) => {
    const errors = {};
    const Email_regex =
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)+(?:\.[a-zA-Z]{2,63})$/;

    const phone_regex =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    if (!values.name) {
      errors.name = "Please enter your name";
    }
    if (!values.email) {
      errors.email = "Please enter a valid email";
    }
    if (!Email_regex.test(values.email)) {
      errors.email = "Please enter a valid email";
    }
    if (values.phone.length !== 10 || isNaN(values.phone)) {
      errors.phone = "Please enter a valid 10-digit phone number";
    }
    if (!values.phone) {
      errors.phone = "Please enter a valid phone number";
    }
    if (!phone_regex.test(values.phone)) {
      errors.phone = "Please enter a valid phone number";
    }
    if (!values.gender) {
      errors.gender = "Please select the gender";
    }
    if (!values.pincode || values.pincode.length < 6) {
      errors.pincode = "Please enter a valid pincode";
    }
    if (!values.address) {
      errors.address = "Please enter the address";
    }
    if (!values.district) {
      errors.district = "Please select the state";
    }
    if (!values.city) {
      errors.city = "Please select the District";
    }

    if (params.type) {
      if (!values.code) {
        errors.code = "Please enter the referral code";
      }
    }
    return errors;
  };

  // use  effect calls when have no errors and isSubmit true
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit && isOtpVerified) {
      fetchData();
    }
  }, [formErrors]);
  console.log(formType.franchise);
  useEffect(() => {
    if (isSubmit === true) {
      const sendData = async () => {
        const number = formValues.phone;
        try {
          const phone = "+91" + number;
          const res = await setUpRecaptcha(phone);
          setConfirmObj(res);
          console.log(res, "======>>>>res");

          setOtpDisplay(true);
          setIsSubmit(false);
        } catch (error) {
          if (error.code == "auth/too-many-requests") {
            toast.error("Too many requests. Try again after few hours!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmit(false);
            setState({ ...state, phoneSnack: true });
            setFormValues({ ...formValues, phone: "" });
          } else {
            setState({ ...state, phoneSnack: true });
            setFormValues({ ...formValues, phone: "" });
            console.error(error);
            setIsSubmit(false);
          }
          setFormErrors({
            ...formErrors,
            phone: "Too many requests. Try again after few hours!",
          });
        }
      };
      sendData();
    }
  }, [isSubmit]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="container-fluid p-0 m-0  overflow-hidden">
          <FranchiseHeader />

          <ToastContainer />
          <div className="container-fluid p-0">
            {params.type === "Sub" ? (
              <img
                src={
                  isMobile
                    ? require("../../Assests/webimg/mobsub.jpg")
                    : require("../../Assests/webimg/web_subfranchise.jpg")
                }
                style={{ zIndex: 0, width: "100%" }}
                className="img-fluid"
                alt="web_franchise"
                height={500}
              />
            ) : (
              <img
                src={
                  isMobile
                    ? require("../../Assests/webimg/mobfra.jpg")
                    : require("../../Assests/webimg/web_franchise.jpg")
                }
                style={{ zIndex: 0, width: "100%" }}
                className="img-fluid"
                alt="web_franchise"
                height={500}
              />
            )}
          </div>

          <div
            className="row flex-sm-row-reverse mt-md-4 p-md-5 p-3  "
            id="f-about"
          >
            <div className="col-12 col-md-5 col-lg-4  text-center p-md-5 ">
              <img
                src={require("../../Assests/webimg/web_become.png")}
                className="img-fluid"
                alt="web_franchise"
                height={500}
              />
            </div>
            {params.type === "Sub" ? (
              <div className="col-sm-12 col-md-7 mb-5 py-3 ">
                <Heading className=" fw-bold lh-1 mb-1 text-center text-md-start text-lg-start ">
                  Become a Subfranchise
                </Heading>
                <br />
                <ParaGraph className=" text-sm-center text-lg-start text-dark text-md-justify text-center   ">
                  Iskoolz introduces a new concept of Online Education that is
                  to connect students with new effective and effortless teaching
                  concepts. It assists with an extensive training program based
                  on the business strategies required for operating a successful
                  Online Education Platform and allows you to earn up to
                  10,00,000 INR per month.
                </ParaGraph>
              </div>
            ) : (
              <div className="col-sm-12 col-md-7 mb-5 py-3 p-3">
                <Heading className=" fw-bold lh-1 mb-1 text-sm-center text-md-start text-lg-start text-center">
                  Become a Franchise
                </Heading>
                <br />
                <ParaGraph className=" text-sm-center text-lg-start text-md-start text-center   ">
                  Iskoolz introduces a new concept of Online Education that is
                  to connect students with new effective and effortless teaching
                  concepts. It assists with an extensive training program based
                  on the business strategies required for operating a successful
                  Online Education Platform and allows you to earn up to
                  40,00,000 INR per month.
                </ParaGraph>
              </div>
            )}
          </div>

          {params.type === "Sub" ? (
            <div
              className="container-fluid p-md-4 p-2 text-center"
              id="hanging-icons"
            >
              <Heading className=" fs-3 pb-3">
                Why iskoolz Subfranchise?
              </Heading>
              <br />

              <div className="row  g-4 p-2 row-cols-1 row-cols-md-2 row-cols-lg-2 shadow-sm rounded">
                {FranchiseData.map((item, index) => {
                  return (
                    <Card
                      btn={index + 1}
                      key={index}
                      title={item.heading}
                      para={item.para}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              className="container text-center p-md-4 p-2 "
              id="hanging-icons"
            >
              <Heading className="fs-3 pb-3">Why iskoolz Franchise?</Heading>
              <br />
              <div className="container text-center p-3  ">
                <div className="row g-4 p-2 row-cols-1 row-cols-md-2 row-cols-lg-2   shadow-sm rounded">
                  {FranchiseData.map((item, index) => {
                    return (
                      <Card
                        btn={index + 1}
                        key={index}
                        title={item.heading}
                        para={item.para}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div
            className="row flex-sm-row-reverse align-items-center  p-2 p-md-5 mt-md-4 pt-5  "
            id="f-apply"
          >
            <div className=" col-12 col-md-5 col-lg-5 justify-content-center text-center p-2">
              <img
                src={require("../../Assests/images/subfranchise.png")}
                width={250}
                className="img-fluid"
                alt="web_franchise"
                height={500}
              />
            </div>
            <div className="col-sm-12 col-md-7 col-lg-6   ">
              {params.type === "Sub" ? (
                <SubHeading className=" fw-bold lh-1 mb-1 text-sm-center text-md-start text-lg-start text-center fs-3">
                  Subfranchise Requirements
                </SubHeading>
              ) : (
                <SubHeading className=" fw-bold lh-1 mb-1 text-sm-center text-md-start text-lg-start text-center fs-4">
                  Franchise Requirements
                </SubHeading>
              )}
              <br />
              <ParaGraph
                className=" text-sm-center text-md-start text-lg-start text-md-justify text-center fw-light "
                style={{ letterSpacing: "0" }}
              >
                Please email or call us to know more about{" "}
                {params.type === "Sub" ? "subfranchise" : "franchise"}{" "}
                requirements <br />
                Email us :{" "}
                <span
                  style={{ color: "var(--theme-color)", fontWeight: "500" }}
                >
                  info@iskoolz.com
                </span>
                <br />
                Call us:{" "}
                <span style={{ color: "var(--theme-color", fontWeight: "500" }}>
                  +91 9873008999
                </span>
              </ParaGraph>
            </div>
          </div>

          {isFilled ? (
            <div id="f-contact" className="container-fluid p-2">
              <div className=" col-md-6  mx-auto  formContainer p-4 my-5">
                <SubHeading className=" text-center  ">
                  {params.type === "Sub"
                    ? "Enquire for Iskoolz Subfranchise"
                    : "Enquire for Iskoolz Franchise"}
                </SubHeading>
                <ParaGrey className="text-center text-dark ">
                  Fill up the{" "}
                  {params.type === "Sub" ? "Subfranchise" : "Franchise"}
                  -Enquiry-Form and one of our executives will get in touch with
                  you shortly.
                </ParaGrey>

                <div className="container">
                  <form
                    style={{
                      backgroundImage: `url("${formType.franchise}")`,
                      backgroundSize: "500px 500px",
                      backdropFilter:"-moz-initial",
                      backgroundPosition: "center",
                      backgroundPositionX: "50%",
                      backgroundBlendMode: "color-dodge",
                      backgroundRepeat: "no-repeat",
                      // backgroundImage: `url("${DEMO}")`
                    }}
                    component="form"
                    className={classes.formControl}
                    onSubmit={handleSubmit}
                  >
                    <div className="my-2">
                      <TextField
                        id="standard-multiline-flexible"
                        label="Name"
                        name="name"
                        color="secondary"
                        type="text"
                        margin="normal"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          className: classes.input,
                        }}
                        value={formValues.name}
                        onChange={handleChange}
                        error={formErrors.name}
                        helperText={formErrors.name}
                      />
                    </div>

                    <div className="my-2">
                      <TextField
                        id="standard-multiline-flexible"
                        label="Email"
                        margin="normal"
                        type="email"
                        sx={{
                          width: "100%",
                        }}
                        name="email"
                        color="secondary"
                        variant="standard"
                        error={formErrors.email}
                        InputProps={{
                          className: classes.input,
                        }}
                        onChange={handleChange}
                        value={formValues.email}
                        helperText={formErrors.email}
                      />
                    </div>

                    <div className="my-2">
                      <TextField
                        id="standard-multiline-flexible"
                        label="Phone"
                        margin="normal"
                        type="number"
                        name="phone"
                        color="secondary"
                        variant="standard"
                        error={formErrors.phone}
                        onWheel={() => document.activeElement.blur()}
                        sx={{
                          width: "100%",
                        }}
                        onInput={(e) => {
                          e.target.value = parseInt(e.target.value)
                            .toString()
                            .slice(0, 10);
                        }}
                        InputProps={{
                          className: classes.input,
                        }}
                        value={formValues.phone}
                        onChange={handleChange}
                        helperText={formErrors.phone}
                      />
                    </div>

                    <div className="my-2">
                      <FormControl
                        variant="standard"
                        fullWidth
                        color="secondary"
                        margin="normal"
                        error={formErrors.gender}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Gender
                        </InputLabel>
                        <Select
                          value={formValues.gender}
                          fullWidth
                          onChange={handleChange}
                          label="Gender"
                          name="gender"
                        >
                          <MenuItem value=" " disabled={true}>
                            <em>SelectGender</em>
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </Select>
                      </FormControl>

                      <small
                        className="text-danger"
                        style={{
                          color: "#d32f2f",

                          fontWeight: "400",
                          fontSize: "0.85rem",
                          lineHeight: "1.66",
                          letterSpacing: "0.0em",
                          textAlign: "left",

                          margin: "0",
                        }}
                      >
                        {formErrors.gender}
                      </small>
                    </div>

                    <div className="my-2">
                      <TextField
                        id="standard-multiline-flexible"
                        label="Enter Pin Code"
                        placeholder="Enter Your Pincode"
                        margin="normal"
                        type="number"
                        name="pincode"
                        value={formValues.pincode}
                        onChange={handleChange}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        error={formErrors.pincode}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        InputProps={{
                          className: classes.input,
                          maxlength: 6,
                        }}
                      />
                      <small className="text-danger">
                        {formErrors.pincode}
                      </small>
                    </div>

                    <div className="my-2">
                      <TextField
                        id="standard-multiline-flexible"
                        label="Your Address"
                        placeholder="Enter Your Address"
                        margin="normal"
                        name="address"
                        rows={2}
                        error={formErrors.address}
                        value={formValues.address}
                        onChange={handleChange}
                        fullWidth
                        color="secondary"
                        variant="standard"
                        InputProps={{
                          className: classes.input,
                        }}
                      />
                      <small className="text-danger">
                        {formErrors.address}
                      </small>
                    </div>
                    <div className="my-2">
                      <FormControl
                        variant="standard"
                        fullWidth
                        color="secondary"
                        margin="normal"
                        error={formErrors.district}
                        helperText={formErrors.district}
                      >
                        <InputLabel id="demo-simple-select-standard-label">
                          Select State
                        </InputLabel>
                        <Select
                          name="district"
                          fullWidth
                          onChange={(e) => handleChange(e)}
                          value={formValues.district}
                          defaultValue={formValues.district}
                          label="State"
                        >
                          {dataJson.states.map((item, i) => {
                            return (
                              <MenuItem key={item.state} value={item.state}>
                                <span> {item.state} </span>
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <small className="text-danger">
                        {formErrors.district}
                      </small>
                    </div>

                    {formValues.district.length ? (
                      <div className="my-2">
                        <FormControl
                          variant="standard"
                          fullWidth
                          color="secondary"
                          error={formErrors.city}
                          margin="normal"
                        >
                          <InputLabel id="demo-simple-select-standard-label">
                            Select District
                          </InputLabel>
                          <Select
                            fullWidth
                            label="City"
                            name="city"
                            value={formValues.city}
                            onChange={handleChange}
                          >
                            {districts.map((item, i) => {
                              return (
                                <MenuItem value={item} key={i}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <small className="text-danger">{formErrors.city}</small>
                      </div>
                    ) : (
                      ""
                    )}

                    {params.type && (
                      <div className="my-2">
                        <TextField
                          id="standard-multiline-flexible"
                          label="Referral Code"
                          margin="normal"
                          helperText={formErrors.code}
                          error={formErrors.code}
                          type="text"
                          name="code"
                          value={formValues.code}
                          onChange={handleChange}
                          fullWidth
                          color="secondary"
                          variant="standard"
                          InputProps={{
                            className: classes.input,
                          }}
                        />
                        <small style={{ fontSize: "13px" }}>
                          NOTE: if you don't have any referral code, you can use
                          ISKOOLZ official referral code
                        </small>

                        <div
                          className="input-group w-25 text-body"
                          style={{ marginTop: "11px" }}
                        >
                          <input
                            type="text"
                            value={text}
                            className="form-control bg-transparent border-dark fw-bold  "
                            disabled
                            readonly
                          />
                          <Tooltip title="Click here to copy referral code">
                            <span
                              className="input-group-text bg-transparent border-dark"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              data-bs-title="Tooltip on right"
                              style={{ cursor: "pointer" }}
                              onClick={handleCopy}
                            >
                              <FiCopy />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    )}

                    {isSubmit && (
                      <div id="recaptcha-container" className="col-4 "></div>
                    )}

                    {
                      <div style={{ textAlign: "end " }} className="mt-5">
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={disable}
                          style={{
                            borderRadius: "25px",
                            fontFamily: "var(--para-font)",
                            fontSize: "17px",
                            width: "120px",
                            height: "50px",
                            textTransform: "none",
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    }
                  </form>

                  <Stack fullWidth>
                    <Snackbar
                      anchorOrigin={{ vertical, horizontal }}
                      autoHideDuration={4000}
                      fullWidth
                      open={open}
                      onClose={handleClose}
                      message="Please fill up all the fields carefully"
                      key={vertical + horizontal}
                      sx={{
                        width: "100%",

                        "& .MuiSnackbarContent-root": {
                          width: "100%",
                          position: "fixed",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          borderRadius: 0,
                        },
                      }}
                    />
                    <Snackbar
                      anchorOrigin={{ vertical, horizontal }}
                      autoHideDuration={4000}
                      fullWidth
                      open={state.phoneSnack}
                      onClose={() => setState({ ...state, phoneSnack: false })}
                      message="Invalid Number !!!!"
                      key={vertical + horizontal}
                      sx={{
                        width: "100%",

                        "& .MuiSnackbarContent-root": {
                          width: "100%",
                          position: "fixed",
                          bottom: 0,
                          left: 0,
                          right: 0,
                          borderRadius: 0,
                        },
                      }}
                    />
                  </Stack>

                  {loading && <Spinner />}
                  <Modal
                    open={otpDisplay}
                    onClose={() => {
                      setFormValues({ ...formValues, phone: "" });
                      setOtpDisplay(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ zIndex: "150" }}
                  >
                    <div style={styleModal}>
                      <img
                        src={require("../../Assests/images/iskoolz_logo.png")}
                        width="150px"
                      />
                      <SubHeading className="p-3">
                        Please enter OTP sent to your mobile +91
                        {formValues.phone}
                      </SubHeading>
                      <form onSubmit={verifyOtp}>
                        <div className="px-2 mx-3">
                          <TextField
                            inputProps={{ maxLength: 6 }}
                            id="filled-basic"
                            fullWidth
                            InputProps={{
                              className: classes.input,
                            }}
                            onChange={(e) => setOtp(e.target.value)}
                            value={otp}
                            type="text"
                            name="otp"
                            color="primary"
                            label="Enter OTP"
                            autoFocus={true}
                            variant="standard"
                            placeholder="Enter OTP"
                          />
                        </div>
                        <div className="float-end">
                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            sx={{
                              borderRadius: "20px",
                              textTransform: "none",
                              m: 3,
                              fontFamily: "var(--para-font)",
                              fontSize: "12px",
                            }}
                          >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          ) : (
            <div
              class="card"
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                class="container text-center"
                style={{
                  width: "40%",
                  height: "30vh",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  padding: "40px",
                  transition: "0.3s",
                  borderRadius: 12,
                }}
              >
                <h4>
                  <b>Your request status</b>
                </h4>
                <AssignmentTurnedIn
                  sx={{
                    color: "yellow",
                    fontSize: 60,
                  }}
                />
                <p>
                  You have already applied for {filledType} Status : Pending
                </p>
              </div>
            </div>
          )}

          {/* Card Design  */}
        </div>
        <Footer />
      </ThemeProvider>
    </>
  );
}

export default Franchise;
