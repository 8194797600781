import React from "react";
import { Heading, ParaGraph } from "../Styles/Style";
import "../Styles/GetApp.css";
import CommentSwiper from "./../Common/CommentSwiper";
import useMediaQuery from "@mui/material/useMediaQuery";

function GetApp() {
  const matchesXXL = useMediaQuery("(min-width:1400px)");
  const matchesLG = useMediaQuery("(min-width:992px)");
  const matchesXS = useMediaQuery("(max-width:400px)");

  return (
    <div
      id="getApp"
      className=" p-5 "
      style={{ border: "0px solid red", position: "relative" }}
    >
      <div
        className="backgroundDiv d-none d-md-block"
        style={{
          position: "absolute",
          border: "0px solid red",
          zIndex: "20",
          width: matchesXXL ? "50%" : "55%",
          height: matchesLG ? "50%" : "45%",
          top: matchesLG ? "25%" : "27%",
          right: "0",
        }}
      ></div>

      <div
        className=" getApp row flex-lg-row-reverse flex-md-row-reverse align-items-center justify-content-center"
        style={{ border: "0px solid green" }}
      >
        <div
          className="col-12 col-md-6 col-lg-6 "
          style={{
            border: "0px solid blue",
            zIndex: "100",
          }}
        >
          <img
            src={require("../Assests/webimg/appLink.png")}
            className="d-block mx-lg-auto img-fluid mobImage"
            alt="shine_icon"
            width="500"
            height="500"
            loading="lazy"
            style={{ zIndex: "100" }}
          />
        </div>
        <div
          className={`col-lg-5 col-12 col-md-6 text-lg-start text-md-start text-center ${
            matchesXS ? "py-4 px-1" : "p-5"
          } `}
        >
          <Heading className={`${matchesXS ? "fs-4" : "fs-1"} `}>
            Get the Iskoolz app
          </Heading>
          <br />
          <ParaGraph className={`${matchesXS ? "fs-6" : "fs-5"} `}>
            Download lessons and learn anytime, <br /> anywhere with the iskoolz
            app
          </ParaGraph>
          <img
            src={require("../Assests/images/app_qr.png")}
            alt="app-qr"
            width="170"
            height="170"
            className="qrCode"
            loading="lazy"
          />
          <a href="https://play.google.com/store/apps/details?id=com.iskoolz.education&pli=1">
            <img
              src={require("../Assests/images/playStore.png")}
              className="d-block img-fluid mt-4 playImg "
              alt="shine_icon"
              width="170"
              height="170"
              loading="lazy"
            />
          </a>
        </div>
      </div>
      {/* 
      <div className="container text-center my-5 ">
        <Heading className="fw-normal">
          Why do parents love <br /> iskoolz classes?
        </Heading>
      <div className=" my-5 container">
        <CommentSwiper />
      </div>
      </div> */}
    </div>
  );
}

export default GetApp;
