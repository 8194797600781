/* eslint-disable react/jsx-pascal-case */
import React,{useContext} from "react";
import styled from "styled-components";
import Card from "./../Common/Card";
import { whyiskoolzData } from "../Services/Data";
import OutlinedCard from "./../Common/OutlineCard";
import { LearningData } from "./../Services/LearningData";
import { Heading, ParaGraph, SubHeading } from "../Styles/Style";

function About() {
 
  return (
    <div id="about" className="pt-3 container-fluid">
      <div className=" container-xxl  text-center mt-3 pt-2">
        <Heading className="py-4 text-center"> About us</Heading>
        {/* Heading -content */}
        <div className="col-lg-12 col-md-11  mx-auto ">
          <ParaGraph>
            Every learner deserves the best learning opportunities to become
            future ready. Learning takes place throughout our life at any age.
            <br />
            <br />
            A strong foundation of learning is set for students not only from
            the text books they read, but there is a requirement of interactive
            learning with practical approach.
            <br /> <br />
            Students must develop interpersonal skills and think innovatively.
            This requires a method of personalised, interactive and creative
            learning which can be made possible through technology.
            <br /> <br />
            Considering all these factors, Iskoolz has come up with a wonderful
            approach of teaching anyone, anywhere, anytime, across the globe,
            with most interactive teaching & learning methodologies.
          </ParaGraph>
        </div>

        {/* Left content right image */}
        <div className="row flex-lg-row-reverse flex-md-row-reverse align-items-center pt-md-5 ps-md-4 mt-md-5 mb-md-5 justify-content-center">
          <div className="col-12 col-sm-8 col-md-5 col-lg-5 ">
            <img
              src={require("../Assests/images/shine_Icon.png")}
              className="d-block mx-lg-auto img-fluid"
              alt="shine_icon"
              width="350"
              height="350"
              loading="lazy"
            />
          </div>
          <div className="col-lg-7  col-md-7 justify-content-start ">
            <Heading className=" fw-bold  lh-1 mb-3  ps-md-3 text-sm-center text-md-start text-lg-start ">
              Choose the place where <br /> you can shine.
            </Heading>
            <br />
            <ParaGraph className="ps-md-3 text-sm-center text-lg-start text-md-start ">
              Iskoolz, aims to build a futuristic Environment for Students.
              Real-time LIVE quizzes and previous attempt scores will inspire
              students to perform better.
            </ParaGraph>
            <ParaGraph className="ps-md-3 text-sm-center text-lg-start text-md-start  ">
              Enjoy self-Assessment and Evaluation through Worksheets, Weekly
              Progress Report, and Conceptual understanding through interactive
              Videos.
            </ParaGraph>
          </div>
        </div>
        {/* Why i-skoolz data */}

        <div className="container ">
          <Heading className="py-5 text-center">Why Iskoolz?</Heading>
          <ParaGraph className="text-center ">
            <b>Iskoolz</b> is India's Leading Online School Application, that
            offers interactive Live Sessions and Recorded Videos based on NCERT
            / CBSE pattern. <br /> <br />
            This app is specially designed for learners to enhance their skills
            using high-quality interactive content via animated videos and
            personal teaching. <br /> <br />
            At <b>Iskoolz</b>, we provide educational content in both Hindi and
            English language to make learning effective and effortless. <br />{" "}
            <br />
            It is a platform for everyone who is keen to learn.
            <br /> <br />
            We ensure to provide an uninterrupted learning experience.
          </ParaGraph>
        </div>
        <br />
        <br />

        {/* cards  data in =====>../Services/data.js*/}
        <div
          className="container "
          style={{ borderRadius: "10px", marginBottom: "100px" }}
          id="hanging-icons"
        >
          <div className="row g-3 row-cols-1 row-cols-md-2 shadow-sm rounded">
            {whyiskoolzData.map((item, index) => {
              return (
                <Card
                  key={index}
                  title={item.heading}
                  para1={item.para1}
                  para2={item.para2}
                  para3={item.para3}
                  para4={item.para4}
                  logo={item.logo}
                />
              );
            })}
          </div>
        </div>
        {/* Left image right content  */}
        <div className="row flex-lg-row align-items-center  py-5   my-5 justify-content-center">
          <div className="col-12 col-sm-8 col-md-4 col-lg-5 ">
            <Styleimg
              src={require("../Assests/images/img_promo.png")}
              className=" img-fluid"
              alt="img_promo"
              width="300"
              height="30"
              loading="lazy"
            />
          </div>
          <div className="col-lg-7 col-md-7">
            <Heading className="fw-bold lh-2 mb-3  text-sm-center text-lg-start text-md-start ">
              Turn your fears into strength <br /> with Iskoolz.
            </Heading>
            <SubHeading className=" fw-bold  fs-md-5 lh-1 mb-3 text-sm-center  text-md-start text-lg-start">
              Do you Fear Maths, Coding?
            </SubHeading>

            <ParaGraph className="text-sm-center   text-md-start text-lg-start">
              Iskoolz helps you to strengthen your subject concepts through
              innovative videos and real-life examples. Now, maths will no more
              a fear and Science will no more a burden. You can learn Coding on
              tips and Understand Robotics with crystal clear concepts. With
              Iskoolz experts, you can turn your fears into strength.
            </ParaGraph>
          </div>
        </div>

        {/* LEARNING */}
        <div className=" row flex-col align-items-start p-2 ">
          <div>
            <Heading className="p-3  text-center">
              Levels of Learning with Iskoolz
            </Heading>
            <ParaGraph className="text-center">
              We offer a step-by-step learning path, specially designed for
              students, to learn and self-evaluate their progress of learning
              from anywhere, at any time.
            </ParaGraph>
          </div>
          <div className="row row-cols row-cols-lg-2  mx-auto ">
            {LearningData.map((item, index) => {
              const {
                heading,
                span,
                SubHeading1,
                subHeading2,
                list1,
                list2,
                headContent,
                logo,
                maxlimit,
                minlimit,
              } = item;
              return (
                <OutlinedCard
                  key={index}
                  heading={heading}
                  span={span}
                  Subheading1={SubHeading1}
                  headContent={headContent}
                  Subheading2={subHeading2}
                  list1={list1}
                  list2={list2}
                  logo={logo}
                  maxlimit={maxlimit}
                  minlimit={minlimit}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const Styleimg = styled.img`
  @media (max-width: 500px) {
    margin-top: -70px;
  }
`;

export default About;
