import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useParams } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillHome } from "react-icons/ai";
import { HiDocumentText } from "react-icons/hi";
import { FaInfoCircle } from "react-icons/fa";
import { CgLogIn } from "react-icons/cg";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FiLink } from "react-icons/fi";
import { FaMoneyCheckAlt } from "react-icons/fa";
import FormModal from "./FormModal";
import { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function TemporaryDrawer() {
  const matchesXS = useMediaQuery("(max-width:400px)");

  const [login, setLogin] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [drawerCondition, makeCondition] = useState(false); //This condition is make for if we are in homepage-==> false if we are in franchise it is true

  const find = () => {
    const login = localStorage.getItem("@secure.b.login");

    if (login) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    find();
    let franchise = localStorage.getItem("@secure.s.type");

    if (franchise) {
      makeCondition(true);
    } else {
      makeCondition(false);
    }
  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [modalShow, setModalShow] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className="hamburger-menu mt-0"
      sx={{
        width:
          anchor === "top" || anchor === "bottom"
            ? "auto"
            : matchesXS
            ? 300
            : 350,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{ mt: 0, p: 0 }}>
        <ListItem style={{ gap: matchesXS ? "170px" : "227px" }} disablePadding>
          <Link to="/">
            <ListItemButton
              style={{
                width: matchesXS ? "100px" : "auto",
                height: matchesXS ? "50px" : "auto",
              }}
            >
              <img
                src={require("../Assests/images/iskoolz_logo.png")}
                className="img-fluid"
                alt="iskoolz-icon"
              />
            </ListItemButton>
          </Link>

          <div className="float-end">
            <i
              className="fa-sharp fa-solid fa-xmark"
              onClick={toggleDrawer(anchor, false)}
              style={{
                float: "0px",
                cursor: "pointer",
                paddingRight: "10px",
                fontSize: "25px",
              }}
            ></i>
          </div>
        </ListItem>
      </List>
      <Divider />
      <List>
        {drawerCondition ? (
          <>
            {/* show only when drawer open in  franchise sections*/}{" "}
            {/*Home link in franchise section */}
            <Link
              to="/"
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AiFillHome />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </Link>
            <HashLink
              to="#f-contact"
              smooth
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FaInfoCircle />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </HashLink>
            <HashLink
              to="#f-apply"
              smooth
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <HiDocumentText />
                </ListItemIcon>
                <ListItemText primary="Apply For" />
              </ListItemButton>
            </HashLink>
        
            {params.type === "Sub" ? (
              <ListItemButton onClick={() => navigate("/franchise")}>
                <ListItemIcon>
                  <FiLink />
                </ListItemIcon>
                <ListItemText primary="Switch to Franchise" />
              </ListItemButton>
            ) : (
              <ListItemButton onClick={() => navigate("/franchise/Sub")}>
                <ListItemIcon>
                  <FiLink />
                </ListItemIcon>
                <ListItemText primary="Switch to Subfranchise" />
              </ListItemButton>
            )}
            {login ? (
              <ListItemButton onClick={() => navigate("/dashboard")}>
                <ListItemIcon>
                  <CgProfile />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItemButton>
            ) : (
              <ListItemButton onClick={() => setModalShow(true)}>
                <ListItemIcon>
                  <CgLogIn />
                </ListItemIcon>
                <ListItemText primary="  Login" />
              </ListItemButton>
            )}
          </>
        ) : (
          <>
       
            <HashLink
              to="#about"
              smooth
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FaInfoCircle />
                </ListItemIcon>
                <ListItemText primary="About" />
              </ListItemButton>
            </HashLink>
        
            <HashLink
              smooth
              to="#pricing"
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <FaMoneyCheckAlt />
                </ListItemIcon>
                <ListItemText primary="Pricing" />
              </ListItemButton>
            </HashLink>


            <HashLink
              to="#contact-home"
              className="text-dark"
              style={{ textDecoration: "none" }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BsFillQuestionCircleFill />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </HashLink>

            <ListItemButton onClick={() => navigate("/franchise")}>
              <ListItemIcon>
                <FiLink />
              </ListItemIcon>
              <ListItemText primary="Get a Franchise" />
            </ListItemButton>

            
            {login ? (
              <ListItemButton onClick={() => navigate("/dashboard")}>
                <ListItemIcon>
                  <CgProfile />,
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItemButton>
            ) : (
              <ListItemButton onClick={() => setModalShow(true)}>
                <ListItemIcon>
                  <CgLogIn />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItemButton>
            )}
          </>
        )}

        {/* show only when drawer open in  franchise  sections*/}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className="text-dark"
            style={{ marginRight: "-17px", marginTop: "-10px" }}
          >
            <GiHamburgerMenu className="fs-3" />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
          <FormModal show={modalShow} onHide={() => setModalShow(false)} />
        </React.Fragment>
      ))}
    </div>
  );
}
