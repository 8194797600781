const URL = "https://us-central1-iskoolz-test.cloudfunctions.net/app";

export const contactDetails = (data) => {
  // return fetch(`http://127.0.0.1:5001/iskoolz-test/us-central1/app/api/create`, {
  return fetch(`${URL}/api/createContact`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getFranchise = (data) => {
  console.log(data);
  // console.log("category data",category);
  return fetch(`${URL}/api/createFranchise`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSubFranchise = (data) => {
  console.log(data);
  // console.log("category data",category);
  return fetch(`${URL}/api/createSubFranchise`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const enquiryFetch = (data) => {
  console.log(data);
  // console.log("category data",category);
  return fetch(`${URL}/api/createEnquiry`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const reviewSend = (data) => {
  console.log(data);
  // console.log("category data",category);
  return fetch(`${URL}/api/createReview`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-Type": "application/json",
    },
    body: JSON.stringify({
      name: data.name,
      phone: "+91" + data.phoneNumber,
      state: data.state,
      city: data.districts,
      review: data.review,
    }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const bookDemoData = (data, uid) => {
  console.log(data, "===============>>>>>>>>>>>>bookdemo api data");
  return fetch(`${URL}/api/createDemo`, {
    method: "POST",

    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      demo: data.demo,
      name: data.name,
      phoneNumber: "+91" + data.phoneNumber,
      grade: data.grade,
      subject: data.subjects,
      uid: uid,
    }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const registerDetails = async (data) => {
  return fetch(`${URL}/api/createFrontPageForm`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res;
    })
    .catch((err) => console.log("error shoe", err));
};
export const checkFranchise = async (data) => {
  return fetch(`${URL}/api/checkFranchiseForm`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log("error shoe", err));
};
