import * as yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Email_regex =
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)+(?:\.[a-zA-Z]{2,63})$/;

export const HomeSchema = yup.object({
  name: yup.string().max(25).required("Please enter your name"),
  email: yup
    .string()
    .matches(Email_regex, "Please enter a valid email")
    .required("Please enter a valid email"),
  phone: yup
    .string()
    .min(10)
    .matches(phoneRegExp, "Phone number must be valid and contain 10 digits")
    .required("Phone number must be valid and contain 10 digits"),
  grades: yup
    .string()
    .oneOf(
      [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
        "Coding",
        "Robotics",
      ],
      "Please select a valid grade"
    )
    .required("Please select your grade"),
  subject: yup.string().max(25).required("Please select the subject"),
});

export const ContactUsSchema = yup.object({
  name: yup.string().max(25).required("Please enter your name"),
  email: yup
    .string()
    .matches(Email_regex, "Please enter a valid email")
    .required("Please enter a valid email"),
  phone: yup
    .string()
    .min(10)
    .matches(phoneRegExp, "Phone number must be valid and contain 10 digits")
    .required("Phone number must be valid and contain 10 digits"),
  subject: yup.string().max(25).required(" Please enter the subject"),
  message: yup
    .string()
    .min(25)
    .max(200)
    .required("Please enter your message (Min 25 characters)"),
});
export const enquirySchema = yup.object({
  name: yup.string().max(25).required("Please enter your name"),
  email: yup
    .string()
    .matches(Email_regex, "Please enter a valid email")
    .required("Please enter a valid email"),
  phone: yup
    .string()
    .min(10)
    .matches(phoneRegExp, "Phone number must be valid and contain 10 digits")
    .required("Phone number must be valid and contain 10 digits"),
  grade: yup.string().min(2).max(4).required("Please enter the class"),
  school: yup.string().min(2).max(30).required(" School Name is required"),
});

export const bookDemo = yup.object({
  type: yup.string().max(25).required("type is required"),
  name: yup.string().max(20).required("name is required"),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number must be required"),
  grade: yup.string().required("Please select the class"),
  subject: yup.string().min(2).max(30).required(" Subject Name is required"),
  createdOn: yup.date().default(function () {
    return new Date();
  }),
});

export const AddReviewSchema = yup.object({
  name: yup.string().max(20).required("Name is required"),
  phone: yup
    .string()
    .min(10)
    .max(10)
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number must be required"),
  state: yup.string().max(15).required("State is required"),
  city: yup.string().max(15).required("City is required"),
  review: yup.string().max(100).required("Please write your review"),
  createdOn: yup.date().default(function () {
    return new Date();
  }),
});

export const validateForm = (values, params) => {
  const errors = {};
  const Email_regex =
    /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)+(?:\.[a-zA-Z]{2,63})$/;

  const phone_regex =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  if (!values.name) {
    errors.name = "Please enter your name";
  }
  if (!values.email) {
    errors.email = "Please enter a valid email";
  }
  if (!Email_regex.test(values.email)) {
    errors.email = "Please enter a valid email";
  }
  if (values.phone.length !== 10 || isNaN(values.phone)) {
    errors.phone = "Please enter a valid 10-digit phone number";
  }
  if (!values.phone) {
    errors.phone = "Please enter a valid phone number";
  }
  if (!phone_regex.test(values.phone)) {
    errors.phone = "Please enter a valid phone number";
  }
  if (!values.gender) {
    errors.gender = "Please select the gender";
  }
  if (!values.pincode || values.pincode.length < 6) {
    errors.pincode = "Please enter a valid pincode";
  }
  if (!values.address) {
    errors.address = "Please enter the address";
  }
  if (!values.district) {
    errors.district = "Please select the state";
  }
  if (!values.city) {
    errors.city = "Please select the District";
  }

  if (params.type) {
    if (!values.code) {
      errors.code = "Please enter the referral code";
    }
  }
  return errors;
};
