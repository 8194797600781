import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../Styles/Contact.css";
import { ParaGraph, SubHeading, ParaGrey } from "./../Styles/Style";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { IoCallSharp } from "react-icons/io5";
import { MdDrafts } from "react-icons/md";
import { IoNavigate } from "react-icons/io5";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../Styles/Theme";
import { useStyles } from "../Styles/Theme";

import { useFormik } from "formik";
import { enquirySchema } from "../Services/SignUpSchema";
import { useNavigate } from "react-router-dom";
import { enquiryFetch } from "./allApi";

import { AppBar, Toolbar } from "@mui/material";
import {
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useAuth } from "../Context";

import { ClassList } from "../Services/BookDemo";

function Enquiry() {
  const { notify, setloading, loading, Spinner } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const [disable, setDisable] = useState(false);
  const { touched, errors, handleChange, handleBlur, handleSubmit, values } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        grade: "",
        school: "",
      },
      validationSchema: enquirySchema,
      onSubmit: async (values, action) => {
        setloading(true);
        const data = values;
        setDisable(true);

        await enquiryFetch(data)
          .then(() => {
            setloading(false);
            action.resetForm();
            notify();
          })
          .catch(() => {
            console.log("Data not submitted");
          })
          .finally(() => {
            setDisable(false);
          });
      },
    });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="p-2 m-0">
          <AppBar
            component="nav"
            sx={{ bgcolor: "white", boxShadow: 0, mb: 6, p: 0 }}
          >
            <Toolbar>
              <Tooltip title="Go back">
                <Button
                  size="medium"
                  className="buttonLogo position-fixed"
                  onClick={() => navigate(-1 && "/")}
                  sx={{
                    borderRadius: "50%",
                    fontSize: "25px",
                    marginTop: "-18px",
                    marginLeft: "-10px",

                    height: "50px",
                  }}
                >
                  <MdOutlineArrowBackIosNew />
                </Button>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <div className="text-center mt-5  ">
            <ParaGraph className="fs-2 fw-bold">Enquiry</ParaGraph>
            <ParaGraph>
              Any question or remarks, Just Write us a enquiry!
            </ParaGraph>
          </div>

          <div className="container d-flex flex-md-row flex-column-reverse justify-content-center shadow rounded mb-5 p-2">
            <div className="col-lg-4 col-8 col-sm-12 leftDiv p-3 col-12 col-1 ">
              <div className="my-2 ">
                <SubHeading> Any enquiry:</SubHeading>
                <ParaGraph className="col-lg-10  ColorText">
                  Fill up the form and one of our executives will get in touch
                  with you shortly.
                </ParaGraph>
              </div>
              <div>
                <ul className="nav flex-column">
                  <li className="nav-item mb-4 navigation ">
                    <div className="row">
                      <div className="col-2 ColorText">
                        <IoCallSharp className=" fs-3" />{" "}
                      </div>
                      <ParaGraph className="col-10 ColorText">
                        +91 9873008999
                      </ParaGraph>
                    </div>
                  </li>

                  <li className="nav-item mb-4 navigation ColorText ">
                    <div className="row">
                      <div className="col-2">
                        {" "}
                        <MdDrafts className=" fs-3" />
                      </div>
                      <ParaGraph className="col-10 ColorText">
                        info@iskoolz.com
                      </ParaGraph>
                    </div>
                  </li>
                  <li className="nav-item mb-4 navigation ColorText ">
                    <div className="row">
                      <div className="col-2">
                        {" "}
                        <IoNavigate className=" fs-3" />
                      </div>
                      <ParaGraph className="col-10 ColorText">
                        {" "}
                        Hero Honda Chowk Deepika Square First Floor
                        ,Delhi-Jaipur Highway, Sector 37, Gurugram, Haryana
                        122001
                      </ParaGraph>
                    </div>
                  </li>
                </ul>

                <ParaGrey className="text-dark text-center text-md-start  ">
                  Contact with us on
                </ParaGrey>
                <div className="d-flex gap-3 justify-content-center  justify-content-md-start  justify-content-lg-start ">
                  <a
                    href="https://twitter.com/iskoolzofficial"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../Assests/images/twitter.png")}
                      width={30}
                      alt="twitter-icon"
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UChK3cTG7vWYd5MGS2MsH2Sg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../Assests/images/youtube.png")}
                      width={30}
                      alt="youtube-icon"
                    />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send/?phone=919873008999&text=I+need+help+in+%2AISKOOLZ+APP%2A%2C+Kindly+help+me+to+resolve+my+problem.+Thank+You%21&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../Assests/images/whatsapp.png")}
                      width={30}
                      alt="whatsapp-icon"
                    />
                  </a>
                  <a
                    href="https://en-gb.facebook.com/IskoolzIndia"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={require("../Assests/images/facebook.png")}
                      width={30}
                      alt="facebook-icon"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-8 mx-auto col-lg-7 col-12 rightDiv py-3  ">
              <form
                component="form"
                autoComplete="off"
                className={classes.formControl}
                onSubmit={handleSubmit}
              >
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Name"
                    name="name"
                    color="secondary"
                    type="text"
                    margin="normal"
                    required
                    fullWidth
                    variant="standard"
                    InputProps={{
                      className: classes.input,
                    }}
                    sx={{
                      my: 2,
                    }}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Email"
                    margin="normal"
                    type="email"
                    sx={{
                      my: 2,
                    }}
                    required
                    fullWidth
                    name="email"
                    color="secondary"
                    variant="standard"
                    InputProps={{
                      className: classes.input,
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    value={values.email}
                  />
                </div>
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Phone"
                    margin="normal"
                    type="number"
                    name="phone"
                    required
                    color="secondary"
                    fullWidth
                    variant="standard"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    sx={{
                      my: 2,
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </div>
                <FormControl
                  variant="standard"
                  fullWidth
                  color="secondary"
                  margin="normal"
                  helperText={touched.grade && errors.grade}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Grade*
                  </InputLabel>
                  <Select
                    value={values.grade}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Select Grade"
                    name="grade"
                    error={touched.grade && Boolean(errors.grade)}
                  >
                    <MenuItem value=" " disabled={true}>
                      <em>Select Grade</em>
                    </MenuItem>
                    {ClassList.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <div>
                  <TextField
                    id="standard-multiline-flexible"
                    label="Your school"
                    placeholder="Enter Your school"
                    margin="normal"
                    required
                    name="school"
                    rows={2}
                    value={values.school}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.school && Boolean(errors.school)}
                    helperText={touched.school && errors.school}
                    fullWidth
                    color="secondary"
                    variant="standard"
                    sx={{
                      my: 2,
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </div>
                <div className="mt-5 float-md-end float-center">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={disable}
                    sx={{
                      borderRadius: "25px",
                      textTransform: "none",
                      width: "150px",
                      padding: "5px",
                      fontFamily: "var(--para-font)",
                      fontSize: "15px",
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    Submit
                  </Button>

                  <Button
                    // fullWidth : this button show only on small screen for full width button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    disabled={disable}
                    sx={{
                      borderRadius: "25px",
                      textTransform: "none",
                      padding: "5px",
                      fontFamily: "var(--para-font)",
                      fontSize: "15px",
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
              {loading ? Spinner() : ""}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}

export default Enquiry;
