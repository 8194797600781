import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../Styles/Contact.css";
import { ParaGraph, SubHeading, ParaGrey, Styleli } from "./../Styles/Style";
import { IoCallSharp } from "react-icons/io5";
import { MdDrafts } from "react-icons/md";
import { IoNavigate } from "react-icons/io5";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../Styles/Theme";
import { useStyles } from "../Styles/Theme";
import { useFormik } from "formik";
import { ContactUsSchema } from "../Services/SignUpSchema";
import { useNavigate } from "react-router-dom";
import { contactDetails } from "./allApi";
import { useAuth } from "../Context";
import Snackbar from "@mui/material/Snackbar";
import { Alert, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

function Contact() {
  const matchesQGA = useMediaQuery("(max-width:310px)");
  const classes = useStyles();
  const { loading, setloading, Spinner } = useAuth();
  const [disable, setDisable] = useState(false);
  const [dataRestricted, setDataRestricted] = useState(false);
  const currentDate = new Date();

  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
  });

  const { touched, errors, handleChange, handleBlur, handleSubmit, values } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      },
      validationSchema: ContactUsSchema,
      onSubmit: async (values, action) => {
        const storedDate = localStorage.getItem("submitDate");
        if (storedDate) {
          const myDate = new Date(storedDate);
          const currentDate = new Date();
          const diff = currentDate.getTime() - myDate.getTime();
          if (diff < 3 * 24 * 60 * 60 * 1000) {
            alert("Please try again after 3 days.");
            return action.resetForm();
          }
        }

        localStorage.setItem("submitDate", currentDate.toString());
        const data = values;
        setDisable(true);
        setloading(true);

        contactDetails(data)
          .then(() => {
            action.resetForm();
            setState({ ...state, open: true });
          })
          .catch(() => {
            console.log("Data not submitted");
          })
          .finally(() => {
            setloading(false);
            setDisable(false);
          });
      },
    });

  console.log("loading==>", loading);

  return (
    <ThemeProvider theme={theme}>
      <div className="py-4 my-5 container " id="contact-home">
        <div className="text-center py-4 ">
          <ParaGraph className="fs-2 fw-bold ">Contact</ParaGraph>
          <ParaGraph>For any query or remarks, just contact us!</ParaGraph>
        </div>

        <div
          className="row shadow p-2 flex-column-reverse flex-md-row  "
          style={{ borderRadius: "15px" }}
        >
          <div
            className={`col-lg-4  col-12  ${
              matchesQGA ? "py-4 px-2" : "p-5"
            }  leftDiv overflow-hidden`}
          >
            <div className=" mb-4 ">
              <SubHeading className="text-md-start text-center pb-2">
                Contact information
              </SubHeading>
              <ParaGraph className="col-lg-12 pb-3 text-md-start ColorText d-md-block d-none">
                Fill up the form and one of our executives will get in touch
                with you shortly.
              </ParaGraph>
            </div>
            <div>
              <ul className="nav flex-column">
                <Styleli className="nav-item mb-2 navigation ">
                  <div className="row" style={{ border: "0px solid red" }}>
                    <div
                      className="col-2 ColorText"
                      style={{ border: "0px solid red" }}
                    >
                      <IoCallSharp className=" fs-3" />
                    </div>
                    <ParaGraph className="col-10 text-md-start ColorText ">
                      +91 9873008999
                    </ParaGraph>
                  </div>
                </Styleli>

                <li className="nav-item mb-2 navigation ColorText ">
                  <div className="row">
                    <div className="col-2">
                      <MdDrafts className="fs-3" />
                    </div>
                    <ParaGraph className="col-10 text-md-start ColorText">
                      info@iskoolz.com
                    </ParaGraph>
                  </div>
                </li>
                <li className="nav-item mb-2 navigation ColorText ">
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <IoNavigate className=" fs-3" />
                    </div>
                    <ParaGraph className="col-10 ColorText text-md-start">
                      {" "}
                      Hero Honda Chowk Deepika Square First Floor ,Delhi-Jaipur
                      Highway, Sector 37, Gurugram, Haryana 122001
                    </ParaGraph>
                  </div>
                </li>
              </ul>

              <ParaGrey className="text-dark text-center text-md-start mt-4">
                Connect with us on
              </ParaGrey>
              <div className="d-flex gap-3 justify-content-center  justify-content-md-start  justify-content-lg-start ">
                <a href="https://twitter.com/iskoolzofficial" target="_blank">
                  <img
                    src={require("../Assests/images/twitter.png")}
                    width={30}
                    alt="twitter-icon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UChK3cTG7vWYd5MGS2MsH2Sg"
                  target="_blank"
                >
                  <img
                    src={require("../Assests/images/youtube.png")}
                    width={30}
                    alt="youtube-icon"
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=919873008999&text=I+need+help+in+%2AISKOOLZ+APP%2A%2C+Kindly+help+me+to+resolve+my+problem.+Thank+You%21&type=phone_number&app_absent=0"
                  target="_blank"
                >
                  <img
                    src={require("../Assests/images/whatsapp.png")}
                    width={30}
                    alt="whatsapp-icon"
                  />
                </a>
                <a
                  href="https://en-gb.facebook.com/IskoolzIndia"
                  target="_blank"
                >
                  <img
                    src={require("../Assests/images/facebook.png")}
                    width={30}
                    alt="facebook-icon"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className=" col-lg-8 p-3 col-12 text-center rightDiv overflow-hidden">
            <form
              component="form"
              autoComplete="off"
              className={classes.formControl}
              onSubmit={handleSubmit}
            >
              <div className="mb-2">
                <TextField
                  id="standard-multiline-flexible"
                  label="Name"
                  name="name"
                  color="secondary"
                  type="text"
                  margin="normal"
                  required
                  fullWidth
                  variant="standard"
                  InputProps={{
                    className: classes.input,
                  }}
                  sx={{
                    my: 2,
                  }}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </div>
              <div className="mb-2">
                <TextField
                  id="standard-multiline-flexible"
                  label="Email"
                  margin="normal"
                  type="email"
                  sx={{
                    my: 2,
                  }}
                  required
                  fullWidth
                  name="email"
                  color="secondary"
                  variant="standard"
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                />
              </div>
              <div className="mb-2">
                <TextField
                  id="standard-multiline-flexible"
                  label="Phone"
                  margin="normal"
                  type="number"
                  name="phone"
                  required
                  color="secondary"
                  fullWidth
                  variant="standard"
                  sx={{
                    my: 2,
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </div>
              <div className="mb-2">
                <TextField
                  id="standard-multiline-flexible"
                  label="Subject"
                  margin="normal"
                  required
                  fullWidth
                  name="subject"
                  color="secondary"
                  variant="standard"
                  sx={{
                    my: 2,
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                />
              </div>
              <div className="mb-2">
                <TextField
                  id="standard-multiline-flexible"
                  label="Message"
                  placeholder="Enter Your message"
                  margin="normal"
                  required
                  name="message"
                  rows={2}
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                  fullWidth
                  color="secondary"
                  variant="standard"
                  sx={{
                    my: 2,
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </div>
              <div className="mt-5 float-md-end float-center">
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={disable}
                  sx={{
                    borderRadius: "25px",
                    textTransform: "none",
                    width: "150px",
                    height: "50px",
                    fontFamily: "var(--para-font)",
                    fontSize: "17px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  Submit
                </Button>

                <Button
                  // fullWidth : this button show only on small screen for full width button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={disable}
                  fullWidth
                  sx={{
                    borderRadius: "25px",
                    textTransform: "none",
                    padding: "5px",
                    fontFamily: "var(--para-font)",
                    fontSize: "15px",
                    height: "50px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>
            {loading ? Spinner() : ""}
          </div>
        </div>
        <Stack fullWidth>
          <Snackbar
            anchorOrigin={{
              vertical: state.vertical,
              horizontal: state.horizontal,
            }}
            autoHideDuration={4000}
            fullWidth
            open={state.open}
            onClose={() => setState({ ...state, open: false })}
            message="Form Submitted Successfully"
            key={state.vertical + state.horizontal}
            sx={{
              width: "100%",

              "& .MuiSnackbarContent-root": {
                width: "100%",
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
              },
            }}
          />
        </Stack>
      </div>
    </ThemeProvider>
  );
}

export default Contact;
