import React from "react";
import { MyVerticallyCenteredModal } from "../Common/Modal";
import "../Styles/iframe.css";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { Tooltip } from "@mui/material";

function Iframe() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="you-tube-section">
      <div className="container  mt-lg-5 pt-5 mt-sm-0 text-center">
        <img
          src={require("../Assests/webimg/promo_banner.png")}
          className="img-fluid  rounded-4 myImg "
          alt="i frame img"
        />
        <div title="play"  >
          <Tooltip title="PlayVideo" className="playLogo">
            <AiOutlinePlayCircle 
              className="display-4 fw-bold text-light"
              onClick={() => setModalShow(true)}
            />
          </Tooltip>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Iframe;
