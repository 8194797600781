import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Heading, SubHeading } from "./../Styles/Style";
import { ClassList, getGrade } from "../Services/BookDemo";
import { bookDemoData } from "../Pages/allApi";
import {
  TextField,
  Button,
  FormControl,
  ThemeProvider,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { theme } from "../Styles/Theme";
import { useStyles } from "../Styles/Theme";
import { useAuth } from "../Context";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../Firebase/FireBase";
import { toast } from "react-toastify";
import { AssignmentTurnedIn } from "@mui/icons-material";

function Book(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setUpRecaptcha, loading, setloading, Spinner } = useAuth();
  const initialValues = {
    demo: "",
    name: "",
    phoneNumber: "",
    grade: "",
    subjects: "",
    uid: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [confirmObj, setConfirmObj] = useState("");
  const [showDisplay, setShowDisplay] = useState(false);
  const [disable, setDisable] = useState("");
  const [otpSubmit, setOtpSubmit] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [showSubject, setShowSubject] = useState(false);
  const [success, setSuccess] = useState(false);
  const [uid, setUID] = useState("");

  const gradeData = getGrade(
    parseInt(props.minlimit),
    parseInt(props.maxlimit)
  );

  const fetchSubjects = async (gradeId) => {
    const q = query(collection(db, `grades/${gradeId}/subjects`));
    const snapshot = await getDocs(q);
    const subjects = snapshot.docs.map((doc) => doc.data());
    setSubjects(subjects);
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

    if (e.target.name === "grade") {
      fetchSubjects(e.target.value);
      setShowSubject(e.target.value !== "" ? true : false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const err = validateForm(formValues);
    if (Object.keys(err).length == 0) {
      setIsSubmit(true); // for Submission
      setDisable(true); //for button disable
    }
  };

  const err = {};
  const validateForm = (formValues) => {
    const { name, demo, phoneNumber, grade, subjects } = formValues;

    if (!name) {
      err.name = "Enter your name";
    }
    if (!demo) {
      err.demo = "Please select a demo for";
    }

    if (phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      err.phoneNumber = "Phone must be valid and contain 10 digits";
    }
    if (!grade) {
      err.grade = "Please select a Grade ";
    }
    if (!subjects) {
      err.subjects = "Please select your subject ";
    }
    setErrors(err);
    return err;
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 || isSubmit === true) {
      const sendData = async () => {
        const number = formValues.phoneNumber;
        try {
          const phone = "+91" + number;
          const res = await setUpRecaptcha(phone);
          setConfirmObj(res);
          console.log(res, "======>>>>res");
          setShowDisplay(true);
          setDisable(false);
        } catch (error) {
          if (error.code == "auth/too-many-requests") {
            toast.error("Too many requests. Try again after few hours!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmit(false);
            setErrors({
              ...errors,
              phoneNumber: "Too many requests. Try again after few hours!",
            });
          } else {
            setFormValues({ ...formValues, phoneNumber: "" });
            console.error(error);
            setDisable(false);
            setIsSubmit(false);
          }
        }
      };
      sendData();
    }
  }, [errors, isSubmit]);

  const verifyOtp = async (e) => {
    e.preventDefault();
    setloading(true);
    if (!otp) {
      setErrors((prevErr) => ({
        ...prevErr,
        otp: "Please enter the correct OTP",
      }));
      setloading(false);
      return;
    }

    try {
      const resp = await confirmObj.confirm(otp);
      secureLocalStorage.setItem("login", true);
      setOtpSubmit(true);
      setFormValues({ ...formValues, uid: resp.user.uid });
      localStorage.setItem("ReactUID", resp.user.uid);
      await fetchData(resp.user.uid);
    } catch (err) {
      if (err.code == "auth/invalid-verification-code") {
        toast.error("Wrong Otp!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (err.code == "auth/code-expired") {
        toast.error("Otp Expired!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false); // for Submission
        setDisable(false);
        setShowDisplay(false);
        setErrors({
          ...errors,
          phoneNumber: "",
        });
      }
      setOtp("");
    }
    setloading(false);
  };

  const fetchData = async (data) => {
    console.log("fetch data call", formValues);
    try {
      const demoData = await bookDemoData(formValues, data);
      if (demoData.status == 200) {
        setFormValues(initialValues);
        setSuccess(true);
      } else if (demoData.status == 406) {
        toast.error(
          "you already booked a class for the same subject and grade!",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setIsSubmit(false); // for Submission
        setDisable(false);
        setShowDisplay(false);
        setErrors({
          ...errors,
          phoneNumber:
            "you already booked a class for the same subject and grade!",
        });
        setOtp("");
      } else {
        toast.error("Something went wrong. Please try again!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false); // for Submission
        setDisable(false);
        setShowDisplay(false);
        setOtp("");
      }
    } catch (error) {
      console.error("Data not submitted:", error);
    } finally {
      setDisable(false);
    }
  };

  const ModalClose = props.onHide;
  const close_fun = () => {
    ModalClose();
  };
  useEffect(() => {}, [success]);

  return (
    <Modal
      {...props}
      size="auto"
      // height={80}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spinner />
      <div className="text-center py-3">
        <i
          className="fa-sharp fa-solid fa-xmark  "
          onClick={close_fun}
          style={{
            float: "right",
            cursor: "pointer",
            position: "relative",
            top: "-10px",
            right: "5px",
          }}
        ></i>

        {showDisplay ? (
          success ? (
            <span />
          ) : (
            <div>
              <img
                src={require("../Assests/images/iskoolz_logo.png")}
                width="150px"
              />
              <SubHeading className="p-3">
                Please enter OTP sent to your mobile +91{formValues.phoneNumber}
              </SubHeading>
            </div>
          )
        ) : (
          <div>
            {" "}
            <Heading>Book Demo</Heading>
            <SubHeading>Book a free demo session</SubHeading>
          </div>
        )}
      </div>

      {success ? (
        <div
          className="d-flex"
          style={{
            flexDirection: "column",
            justifyContent: "cetner",
            alignItems: "center",
          }}
        >
          {/* <img src={require("../Assests/images/success.png")} width="150px" /> */}
          <AssignmentTurnedIn
            className="mb-3"
            sx={{ color: "#33bd2a", fontSize: 50 }}
          />
          <h2
            className="text-center"
            style={{ fontSize: 24, fontWeight: "bold" }}
          >
            Your free online counselling session has been booked!
          </h2>
          <p style={{ fontWeight: 600 }}>Iskoolz team will contact you.</p>
        </div>
      ) : (
        ""
      )}

      <ThemeProvider theme={theme}>
        {showDisplay && success && (
          <div className="text-center">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              onClick={close_fun}
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                fontFamily: "var(--para-font)",
                fontSize: "12px",
                mb: 4,
                width: 90,
              }}
            >
              Done
            </Button>
          </div>
        )}

        {showDisplay && !success && (
          <form onSubmit={verifyOtp}>
            <div className="px-2 mx-3">
              <TextField
                inputProps={{ maxLength: 6 }}
                id="filled-basic"
                fullWidth
                InputProps={{
                  className: classes.input,
                }}
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
                type="text"
                name="otp"
                color="primary"
                label="Enter OTP"
                autoFocus={true}
                variant="standard"
                placeholder="Enter OTP"
              />
            </div>
            <div className="float-end">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  m: 3,
                  fontFamily: "var(--para-font)",
                  fontSize: "12px",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        )}

        {!showDisplay && (
          <form onSubmit={handleSubmit} className={classes.formControl}>
            <p className="text-danger">{errors.base}</p>
            <div className="px-2 mx-3">
              <FormControl
                variant="standard"
                fullWidth
                color="secondary"
                margin="normal"
                helperText={errors.demo}
                sx={{ marginBottom: "0" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Demo for
                </InputLabel>
                <Select
                  label="Demo for"
                  name="demo"
                  color="secondary"
                  type="text"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    className: classes.input,
                  }}
                  value={formValues.demo}
                  onChange={handleChange}
                >
                  <MenuItem value=" " disabled={true}>
                    <em>Select Category</em>
                  </MenuItem>

                  <MenuItem value="for me">For me</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
              {errors.demo && (
                <p
                  style={{
                    color: "#d32f2f",

                    fontWeight: "400",
                    fontSize: "0.85rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0em",
                    textAlign: "left",

                    margin: "0",
                  }}
                >
                  {errors.demo}
                </p>
              )}
            </div>
            <div className="px-2 mx-3">
              <TextField
                id="standard-multiline-flexible"
                label="Your name"
                name="name"
                color="secondary"
                type="text"
                margin="normal"
                fullWidth
                variant="standard"
                InputProps={{
                  className: classes.input,
                }}
                sx={{
                  my: 2,
                }}
                onChange={handleChange}
                value={formValues.name}
                error={errors.name}
                helperText={errors.name}
              />
            </div>
            <div className="px-2 mx-3">
              <FormControl
                variant="standard"
                fullWidth
                color="secondary"
                margin="normal"
                // helperText={touched.grade && errors.grade}
                sx={{
                  marginBottom: "0",
                }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Select Grade
                </InputLabel>
                <Select
                  value={formValues.grade}
                  fullWidth
                  onChange={handleChange}
                  label="Select Grade"
                  name="grade"
                  helperText={errors.grade}
                >
                  <MenuItem value=" " disabled={true}>
                    <em>Select Grade</em>
                  </MenuItem>
                  {gradeData.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {errors.grade && (
                <p
                  style={{
                    color: "#d32f2f",

                    fontWeight: "400",
                    fontSize: "0.85rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0em",
                    textAlign: "left",

                    margin: "0",
                  }}
                >
                  {errors.grade}
                </p>
              )}
            </div>

            {/*Sel sub  */}
            <div className="px-2 mx-3">
              {showSubject ? (
                <FormControl
                  className={classes.formControl}
                  variant="standard"
                  fullWidth
                  color="secondary"
                  margin="normal"
                  sx={{
                    marginBottom: "0",
                  }}
                >
                  <InputLabel id="subject-label">Subject</InputLabel>
                  <Select
                    labelId="subject-label"
                    id="subject"
                    name="subjects"
                    value={formValues.subjects}
                    onChange={handleChange}
                    error={Boolean(errors.subjects)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {subjects.map((subjects) => (
                      <MenuItem key={subjects.id} value={subjects.name}>
                        {subjects.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              {errors.subjects && formValues.grade !== "" && (
                <p
                  className=""
                  style={{
                    color: "#d32f2f",

                    fontWeight: "400",
                    fontSize: "0.85rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0em",
                    textAlign: "left",

                    margin: "0",
                  }}
                >
                  {errors.subjects}
                </p>
              )}
            </div>
            <div className="px-2 mx-3">
              <TextField
                label="Enter your phone number"
                name="phoneNumber"
                color="secondary"
                type="number"
                margin="normal"
                value={formValues.phoneNumber}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                fullWidth
                variant="standard"
                InputProps={{
                  className: classes.input,
                }}
                sx={{
                  my: 2,
                }}
                onChange={handleChange}
                error={errors.phoneNumber !== "" ? true : false}
                helperText={errors.phoneNumber}
              />
            </div>
            {isSubmit && (
              <div id="recaptcha-container" className="col-7 "></div>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  m: 3,
                  fontFamily: "var(--para-font)",
                  fontSize: "12px",
                  width: "28%",
                  padding: "10px 0",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </ThemeProvider>
    </Modal>
  );
}

export default Book;
