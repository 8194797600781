import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Franchise from "./Pages/Get a franchise/Franchise";
import React from "react";
import Protected from "./Pages/Proctected";
import Terms from "./Pages/Terms&Privacy/Terms";
import Privacy from "./Pages/Terms&Privacy/Privacy";
import Enquiry from "./Pages/Enquiry";
import DashboardMain from "./Pages/Dashboard/DashboardMain";
import FAQ from "./Pages/Terms&Privacy/FAQ";
import ScrollToTop from "./Components/ScrollOnTop";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";

function App() {
  // Chat boat function
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/61c983a7c82c976b71c3a56a/1fntiki0n";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  });

  const history = createBrowserHistory();

  return (
    <BrowserRouter history={history}>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/franchise/:type" element={<Franchise />} />
        <Route path="/dashboard" element={<Protected Comp={DashboardMain} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
