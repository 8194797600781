import React from "react";
import "../../Styles/Terms.css";
import { AppBar, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const Terms = () => {
  const navigate = useNavigate();
  return (
    <div className="terms-container container py-5  ">
      <Toolbar>
        <Tooltip title="Go back">
          <Button
            size="medium"
            className="buttonLogo position-fixed"
            onClick={() => navigate(-1 && "/")}
            sx={{
              borderRadius: "50%",
              fontSize: "25px",
              marginTop: "-18px",
              marginLeft: "10px",

              height: "50px",
            }}
          >
            <MdOutlineArrowBackIosNew className="text-dark" />
          </Button>
        </Tooltip>
      </Toolbar>
      <h1 className="text-center pb-5 fw-bold">Terms of Services</h1>

      <p>
        Vigilante Digital Careermaker Private Limited is (“we” or “us” or “our”
        as "Company" as Iskoolz - The Online School).
      </p>

      <p>
        These terms and conditions outline the rules and regulations for the use
        of Iskoolz the learning app's Web or App.
      </p>

      <p>
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Iskoolz the learning app if you do
        not agree to take all of the terms and conditions stated on this page.
      </p>

      <p>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You" and
        "Your" refers to you, the person log on this website and compliant to
        the Company’s terms and conditions. "The Company", "Ourselves", "We",
        "Our" and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client’s needs in respect of
        provision of the Company’s stated services, in accordance with and
        subject to, prevailing law of Netherlands. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to same.
      </p>

      <h2>Cookies</h2>

      <p>
        We employ the use of cookies. By accessing Iskoolz the learning app, you
        agreed to use cookies in agreement with the Iskoolz the learning app's
        Privacy Policy.
      </p>

      <p>
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use
        cookies.
      </p>

      <h2>License</h2>

      <p>
        Unless otherwise stated, Iskoolz the learning app and/or its licensors
        own the intellectual property rights for all material on Iskoolz the
        learning app. All intellectual property rights are reserved. You may
        access this from Iskoolz the learning app for your own personal use
        subjected to restrictions set in these terms and conditions.
      </p>

      <p>You must not:</p>
      <ul>
        <li>&nbsp; &nbsp; Republish material from Iskoolz the learning app</li>
        <li>
          &nbsp; &nbsp; Sell, rent or sub-license material from Iskoolz the
          learning app
        </li>
        <li>
          &nbsp; &nbsp; Reproduce, duplicate or copy material from Iskoolz the
          learning app
        </li>
        <li>
          &nbsp; &nbsp; Redistribute content from Iskoolz the learning app
        </li>
      </ul>

      <p>This Agreement shall begin on the date hereof.</p>

      <p>
        Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
        Iskoolz the learning app does not filter, edit, publish or review
        Comments prior to their presence on the website. Comments do not reflect
        the views and opinions of Iskoolz the learning app,its agents and/or
        affiliates. Comments reflect the views and opinions of the person who
        post their views and opinions. To the extent permitted by applicable
        laws, Iskoolz the learning app shall not be liable for the Comments or
        for any liability, damages or expenses caused and/or suffered as a
        result of any use of and/or posting of and/or appearance of the Comments
        on this website.
      </p>

      <p>
        Iskoolz the learning app reserves the right to monitor all Comments and
        to remove any Comments which can be considered inappropriate, offensive
        or causes breach of these Terms and Conditions.
      </p>

      <p>You warrant and represent that:</p>

      <ul>
        <li>
          {" "}
          &nbsp; &nbsp; You are entitled to post the Comments on our website and
          have all necessary licenses and consents to do so;
        </li>
        <li>
          &nbsp; &nbsp; The Comments do not invade any intellectual property
          right, including without limitation copyright, patent or trademark of
          any third party;
        </li>
        <li>
          {" "}
          &nbsp; &nbsp;The Comments do not contain any defamatory, libelous,
          offensive, indecent or otherwise unlawful material which is an
          invasion of privacy
        </li>
        <li>
          {" "}
          &nbsp; &nbsp;The Comments will not be used to solicit or promote
          business or custom or present commercial activities or unlawful
          activity.
        </li>
      </ul>

      <p>
        You hereby grant Iskoolz the learning app a non-exclusive license to
        use, reproduce, edit and authorize others to use, reproduce and edit any
        of your Comments in any and all forms, formats or media.
      </p>

      <h2>Hyperlinking to our Content</h2>

      <p>
        The following organizations may link to our Website without prior
        written approval:
      </p>

      <ul>
        <li> &nbsp; &nbsp; Government agencies;</li>
        <li> &nbsp; &nbsp; Search engines;</li>
        <li>&nbsp; &nbsp; News organizations;</li>
        <li>
          &nbsp; &nbsp; Online directory distributors may link to our Website in
          the same manner as they hyperlink to the Websites of other listed
          businesses; and
        </li>
        <li>
          {" "}
          &nbsp; &nbsp; System wide Accredited Businesses except soliciting
          non-profit organizations, charity shopping malls, and charity
          fundraising groups which may not hyperlink to our Web site.
        </li>
      </ul>

      <p>
        These organizations may link to our home page, to publications or to
        other Website information so long as the link: (a) is not in any way
        deceptive; (b) does not falsely imply sponsorship, endorsement or
        approval of the linking party and its products and/or services; and (c)
        fits within the context of the linking party’s site.
      </p>

      <p>
        We may consider and approve other link requests from the following types
        of organizations:
      </p>

      <ul>
        <li>
          &nbsp; &nbsp; commonly-known consumer and/or business information
          sources;
        </li>
        <li>&nbsp; &nbsp; dot.com community sites;</li>
        <li>
          &nbsp; &nbsp; associations or other groups representing charities;
        </li>
        <li>&nbsp; &nbsp; online directory distributors;</li>
        <li>&nbsp; &nbsp; internet portals;</li>
        <li>&nbsp; &nbsp; accounting, law and consulting firms; and</li>
        <li>&nbsp; &nbsp; educational institutions and trade associations.</li>
      </ul>

      <p>
        We will approve link requests from these organizations if we decide
        that: (a) the link would not make us look unfavorably to ourselves or to
        our accredited businesses; (b) the organization does not have any
        negative records with us; (c) the benefit to us from the visibility of
        the hyperlink compensates the absence of Iskoolz the learning app; and
        (d) the link is in the context of general resource information.
      </p>

      <p>
        These organizations may link to our home page so long as the link: (a)
        is not in any way deceptive; (b) does not falsely imply sponsorship,
        endorsement or approval of the linking party and its products or
        services; and (c) fits within the context of the linking party’s site.
      </p>

      <p>
        If you are one of the organizations listed in paragraph 2 above and are
        interested in linking to our website, you must inform us by sending an
        e-mail to Iskoolz the learning app. Please include your name, your
        organization name, contact information as well as the URL of your site,
        a list of any URLs from which you intend to link to our Website, and a
        list of the URLs on our site to which you would like to link. Wait 2-3
        weeks for a response.
      </p>

      <p>Approved organizations may hyperlink to our Website as follows:</p>

      <ul>
        <li>&nbsp; &nbsp; By use of our corporate name; or</li>
        <li>
          &nbsp; &nbsp; By use of the uniform resource locator being linked to;
          or
        </li>
        <li>
          &nbsp; &nbsp; By use of any other description of our Website being
          linked to that makes sense within the context and format of content on
          the linking party’s site.
        </li>
      </ul>

      <p>
        No use of Iskoolz the learning app's logo or other artwork will be
        allowed for linking absent a trademark license agreement.
      </p>

      <h2>iFrames</h2>

      <p>
        Without prior approval and written permission, you may not create frames
        around our Webpages that alter in any way the visual presentation or
        appearance of our Website.
      </p>

      <h2>Content Liability</h2>

      <p>
        We shall not be hold responsible for any content that appears on your
        Website. You agree to protect and defend us against all claims that is
        rising on your Website. No link(s) should appear on any Website that may
        be interpreted as libelous, obscene or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of,
        any third party rights.
      </p>

      <h2>Your Privacy</h2>

      <p>Please read Privacy Policy</p>

      <h2>Reservation of Rights</h2>

      <p>
        We reserve the right to request that you remove all links or any
        particular link to our Website. You approve to immediately remove all
        links to our Website upon request. We also reserve the right to amen
        these terms and conditions and it’s linking policy at any time. By
        continuously linking to our Website, you agree to be bound to and follow
        these linking terms and conditions.
      </p>

      <h2>Removal of links from our website</h2>

      <p>
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </p>

      <p>
        We do not ensure that the information on this website is correct, we do
        not warrant its completeness or accuracy; nor do we promise to ensure
        that the website remains available or that the material on the website
        is kept up to date.
      </p>

      <h2>Disclaimer</h2>

      <p>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </p>

      <ul>
        <li>
          {" "}
          &nbsp; &nbsp; limit or exclude our or your liability for death or
          personal injury;
        </li>
        <li>
          &nbsp; &nbsp; limit or exclude our or your liability for fraud or
          fraudulent misrepresentation;
        </li>
        <li>
          {" "}
          &nbsp; &nbsp; limit any of our or your liabilities in any way that is
          not permitted under applicable law; or
        </li>
        <li>
          {" "}
          &nbsp; &nbsp; exclude any of our or your liabilities that may not be
          excluded under applicable law.
        </li>
      </ul>

      <p>
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort and for breach of
        statutory duty.
      </p>

      <p>
        As long as the website and the information and services on the website
        are provided, we will not be liable for any loss or damage of any
        nature.
      </p>

      <h2>Cancellation & Refund Policy </h2>
      <p>
        If you are not satisfied with our product or services purchased, you may
        contact us within 1 month from the purchase date and we will provide a
        full refund of your purchase amount. Please submit your request at
        info@Iskoolz the learning app.com
      </p>
    </div>
  );
};

export default Terms;
