import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { FaRupeeSign } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  StyleUl,
  HeadingPink,
  ParaGraph,
  MiniHeading,
  Styleli,
} from "../Styles/Style";
import { FcCheckmark } from "react-icons/fc";
import useMediaQuery from "@mui/material/useMediaQuery";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.7rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    color: "var(--theme-color)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    marginTop: "25px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props) {
  const matchesXS = useMediaQuery("(max-width:400px)");
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel"}
      onChange={handleChange("panel")}
      sx={{
        borderRadius: "10px",
        border: "none",
        boxShadow: 3,
        width: "100%",
        border: "0px solid red",
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={{
          backgroundColor: expanded ? "white" : props.color,
          borderRadius: "10px",
          height: "8rem",
        }}
      >
        <HeadingPink>
          {props.title}
          <ParaGraph Name="fs-6 fw-light">
            Hurry Up <br />
            <HeadingPink>
              <FaRupeeSign className="mb-1" />
              {props.price1}
            </HeadingPink>
            <small> / Year</small>
            <br />
            {/* {props.price2 === "" ? (
              ""
            ) : (
              <>
                {" "}
                <HeadingPink>
                  <FaRupeeSign  className="mb-1" />
                  {props.price2}
                </HeadingPink>
                <small> / 3 Years</small>
              </>
            )} */}
          </ParaGraph>
        </HeadingPink>
      </AccordionSummary>
      <AccordionDetails>
        <MiniHeading className="card-title pricing-card-title text-start">
          <small>What we offer:</small>
        </MiniHeading>
        <br />
        <StyleUl className="list-unstyled text-justify text-start">
          {props.offer.map((data, index) => {
            return (
              <Styleli key={index}>
                {" "}
                <div className="row">
                  <div className="col-1">
                    <FcCheckmark />
                  </div>
                  <div className="col">{data}</div>
                </div>
              </Styleli>
            );
          })}
        </StyleUl>
      </AccordionDetails>
    </Accordion>
  );
}
