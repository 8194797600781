import React from "react";
import About from "../Components/About";
import Banner from "./../Components/Banner";
import Pricing from "./../Components/Pricing";
import Iframe from "./../Components/Iframe";
import GetApp from "./../Components/GetApp";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import secureLocalStorage from "react-secure-storage";
import { MiniHeading, ParaGraph } from "../Styles/Style";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";
import CommenSwipwer from "../Components/CommenSwipwer";

function Home() {
  const naviagte = useNavigate();
  useEffect(() => {
    secureLocalStorage.removeItem("type", "franchise");
  }, []);
  return (
    <div className="container-fluid m-0 p-0 text-center" id="home">
      <Header />
      <Banner />
      <About />
      <Pricing />
      <Iframe />
      <GetApp />
      <CommenSwipwer />
      <Contact />

      {/* Upper content start */}
      <div className="container-fluid " style={{ background: "#02bfd4" }}>
        <div className=" row row-cols-1 row-cols-md-2 py-4  align-content-center ">
          <div className="col-md-5 col-12 text-lg-start text-center  offset-md-1">
            <MiniHeading className="text-light fs-5 m-1 ">
              FEEL FREE TO ASK US
            </MiniHeading>
            <ParaGraph className="text-light fs-6 m-1">
              if you have any question, please feel free to drop us questions.
            </ParaGraph>
          </div>
          <div className=" text-lg-end  text-center  col-md-5 col-12 py-1">
            <Button
              onClick={() => naviagte("/enquiry")}
              size="large"
              variant="contained"
              style={{
                width: "150px",
                borderRadius: "5px",
                backgroundColor: "var( --theme-color)",
                textTransform: "none",
                fontSize: "13px",
                margin: "8px",
                fontFamily: "var(--heading-font)",
              }}
            >
              ENQUIRY
            </Button>
          </div>
        </div>
      </div>
      {/* Upper content end */}
      <Footer />
    </div>
  );
}

export default Home;
