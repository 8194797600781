import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { SiMicrosoftoffice } from "react-icons/si";
import { AiFillDashboard } from "react-icons/ai";




// Drawer data in header section
// export const DrawerData = [
  
  
//   {
//     id: 3,
//     title: "Contact",
//     logo: <MdOutlineConnectWithoutContact />,
//     path: "/contact",
//   },
//   {
//     id: 4,
//     title: "Get a Franchise",
//     logo: <SiMicrosoftoffice />,
//     path: "/franchise",
//   },
//   {
//     id: 5,
//     title: "Login",
//     logo: <AiFillDashboard />,
//     path: "/",
//   },
// ];
// // Drawer data end

// Cards data of About section in Home page
export const whyiskoolzData = [
  {
    logo: require("../Assests/webimg/img1.png"),
    heading: "Easy & Interactive",
    para1: "Avail easy to understand content with Animated videos, Worksheets and Test Series.",
    para2: "Build your essential skills.",
  },
  
  {
    logo: require("../Assests/webimg/img2.png"),
    heading: "Learn from Anywhere, Anytime",
    para1: "Self learning approach.",
    para2: "Students can learn.",
  },
  
  {
    logo: require("../Assests/webimg/img3.png"),
    heading: "Value for Money",
    para1: "All contents available in the best affordable price.",
  },
 
  {
    logo: require("../Assests/webimg/img4.png"),
    heading: "Online Lectures / Sessions",
    para1: "According to the latest NCERT / CBSE pattern.",
      para2:'Designed by Subject Matter Experts.',
       para3:"   Significantly will help to improve scores.",
    para4:"Personalized extra classes and doubt sessions."      

  },
];



export const FaQ = [
  {
    faq: "How do i get started?",
    faqAns:
        "Iskoolz learning programs are for students from Class 1st to Class 12th. We also offer programs for Coding & Robotics.",
  },
  {
    faq: "Are there free demo classes?",
    faqAns:
        "Yes, one can check our youtube channel to get free demo classes.",
  },
  {
    faq: "Do Iskoolz give revision classes?",
    faqAns: "Yes, at the time exams, Revision class will be provided.",
  },
  {
    faq: "Where can i track my child's progress?",
    faqAns:
        "A child’s progress can be accessed through the Parent Zone section in the app. A progress report is available for every child and can be accessed by switching the profile.",
  },
]