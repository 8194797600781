import React, { useState, useEffect, useContext } from "react";
import { auth } from "./Firebase/FireBase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export const Context = React.createContext();

export function useAuth() {
  return useContext(Context);
}

export const ContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState("");
  const notify = () => toast.success("Your form is submitted");
  const failToast = () => toast.error("Form not submit");
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setCurrentUser(currentUser);
    });
    return unsubscribe;
  }, []);

  function setUpRecaptcha(number) {
    const recaptchaVerifier = (window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",

      {},
      auth
    ));
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function logOut() {
    return signOut(auth);
  }

  // ....................//
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const Spinner = () => {
    return (
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: "200" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <ToastContainer />

      <Context.Provider
        value={{
          isMobile,
          handleResize,
          currentUser,
          setUpRecaptcha,
          googleSignIn,
          logOut,
          notify,
          failToast,
          setloading,
          loading,
          Spinner,
        }}
      >
        {children}
      </Context.Provider>
    </>
  );
};
