import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main:"#393a3b",
    },
    secondary: {
      main: "#E50B63",
    },
  },
  
});

export const useStyles = makeStyles((theme) => ({
  myButton: {
    borderRadius: "20px",
    float: "right",
    fontFamily: "var(--para-font)",
    fontSize: "12px",
  },

  accordian:{
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 70px 0px",
  padding:"20px"
  },
  faq:{
   color:"red"
  },

  // input: {
  //   // fontFamily: "var(--para-font)",
  //   fontSize: "13px",

  // },
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },

  formControl: {
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "0.5px solid #7d0b28",
    },
    hover: {
      "&:hover": {
        background:"grey",
      
      },
    },
  },
}));


