import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useStyles } from "../Styles/Theme";
import { useFormik } from "formik";
import { HomeSchema } from "../Services/SignUpSchema";
import { contactDetails, registerDetails } from "../Pages/allApi";
import { useAuth } from "../Context";
import "../Styles/MainForm.css";
import secureLocalStorage from "react-secure-storage";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuItem from "@mui/material/MenuItem";
import { ClassList } from "../Services/BookDemo";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../Firebase/FireBase";
import bannerMain from "../Assests/images/bannerMain.jpg";
import mob_slider_banner_01 from "../Assests/webimg/mob_slider_banner_01.jpg";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useContext } from "react";
import { UserContext } from "../imageContext";

const MainForm = () => {
  const { formType } = useContext(UserContext);
  console.log(formType.demo);
  const DEMO = "cat";

  const classes = useStyles();
  const tabWidth = useMediaQuery("(min-width:799px)");
  const tabMaxWidth = useMediaQuery("(max-width:1100px");
  const tabHeight = useMediaQuery("(min-height:800px)");
  const matchesXS = useMediaQuery("(max-width:400px)");
  const matchesXSM = useMediaQuery("(max-width:530px)");
  const matchesSM = useMediaQuery("(max-width:768px)");
  const maxHeightTop = useMediaQuery("(min-height:768px)");
  const matchesMD = useMediaQuery("(min-width:768px)");
  const matchesLG = useMediaQuery("(min-width:992px)");
  const matchesXL = useMediaQuery("(min-width:1200px)");
  const matchesXXL = useMediaQuery("(min-width:1400px)");

  const { uid, setUID } = useState("");
  const { loading, setloading, Spinner } = useAuth();
  const [disable, setDisable] = useState(false);
  const [sendOTP, setSendOTP] = useState(false);
  const [ShowOTPDisplay, setShowOTPDisplay] = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmObj, setConfirmObj] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isOTPverified, setisOTPverified] = useState(false);
  const [submitDisable, setSubmitDisable] = useState(true);
  const [otpDisable, setOtpDisable] = useState(false);
  const [userUID, setUserUID] = useState("");
  const { setUpRecaptcha } = useAuth();
  const [subjectList, setSubjectList] = useState([]);
  const [showSubject, setShowSubject] = useState(false);
  const [doneForm, setDoneForm] = useState(false);

  const fetchSubjects = async (gradeId) => {
    const q = query(collection(db, `grades/${gradeId}/subjects`));
    const snapshot = await getDocs(q);
    const subjects = snapshot.docs.map((doc) => doc.data());
    setSubjectList(subjects);
  };

  const handleGradeChange = (e) => {
    fetchSubjects(e.target.value);
    setShowSubject(e.target.value !== "" ? true : false);
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setloading(true);
    if (otp === "" || otp === null) {
      setloading(false);
      return;
    }

    try {
      const response = await confirmObj.confirm(e.target.value);
      secureLocalStorage.setItem("login", true);
      setloading(false);
      console.log("correct otp");
      setUserUID(response.user.uid);
      localStorage.setItem("ReactUID", response.user.uid);
      setShowOTPDisplay(false);
      setDisable(true);
      setisOTPverified(true);
      setSubmitDisable(false);
      setOtpDisable(true);
      setOtp("");
      toast.success("Number Verified!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (err) {
      if (err.code == "auth/invalid-verification-code") {
        toast.error("Wrong Otp!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (err.code == "auth/code-expired") {
        toast.error("Otp Expired!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDisable(false);
        setShowOTPDisplay(false);
      }

      setloading(false);
      setOtp("");
    }
  };

  const { touched, errors, handleChange, handleBlur, handleSubmit, values } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        otp: "",
        grades: "",
      },
      validationSchema: HomeSchema,
      onSubmit: async (values, action) => {
        const data = values;
        setloading(true);

        if (isOTPverified) {
          const classData = await registerDetails({
            name: values.name,
            email: values.email,
            phone: values.phone,
            subject: values.subject,
            grades: values.grades,
            uid: userUID,
          });
          setloading(false);
          if (classData.status == 200) {
            action.resetForm();
            toast.success("Free Class registered.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setUserUID("");
            setShowOTPDisplay(false);
            setDisable(false);
            setisOTPverified(false);
            setSubmitDisable(true);
            setOtpDisable(false);
            setOtp("");
            setDoneForm(true);
          } else if (classData.status == 406) {
            setDisable(false);
            setisOTPverified(false);
            setSubmitDisable(true);
            values.phone = "";
            errors.phone = "Phone number already registered!";
            toast.error("Phone number already registered!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            console.log("Data not submitted", classData.status);
            toast.error("Something went wrong. Please try again!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setDisable(true);
          }
          console.log(errors.phone);
          setloading(false);
          console.log(errors.phone);
        }
      },
    });

  useEffect(() => {
    if (sendOTP === true) {
      const sendData = async () => {
        const number = values.phone;
        try {
          const phone = "+91" + number;
          const res = await setUpRecaptcha(phone);
          setConfirmObj(res);
          // setUID(res.user.uid);
          // localStorage.setItem("ReactUID", res.user.uid);
          console.log(res, "======>>>>res");
          setSendOTP(false);
          setShowOTPDisplay(true);
          setDisable(true);
        } catch (error) {
          if (error.code == "auth/too-many-requests") {
            toast.error("Too many requests. Try again after few hours!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            errors.phone = "Too many requests. Try again after few hours!";
            setSendOTP(false);
            setDisable(false);
          } else {
            setSendOTP(false);
            setDisable(false);
            errors.phone = "";
          }
          console.error(error);
        }
      };
      sendData();
    }
  }, [sendOTP]);

  return (
    <div
      className="main-banner-div"
      style={{
        position: "relative",
        height:
          tabHeight && tabWidth && tabMaxWidth
            ? "100vh"
            : matchesSM
            ? "900px"
            : "100vh",
        width: "100%",
        backgroundImage:
          (!matchesSM || (!tabHeight && !tabWidth && !tabMaxWidth)) &&
          `url(${bannerMain})`,
        backgroundRepeat: !matchesSM && "no-repeat",
        backgroundSize: !matchesSM && "cover",
        backgroundPosition: !matchesSM && "left",
      }}
    >
      {(matchesSM || (tabHeight && tabWidth && tabMaxWidth)) && (
        <img
          src={require("../Assests/webimg/mob_slider_banner_01.jpg")}
          width="100%"
          height={tabHeight && tabWidth && tabMaxWidth ? "100%" : "900px"}
        />
      )}

      <div
        className="mainForm p-3 form-container overflow-hidden"
        style={{
          backgroundImage: `url("${formType.demo}"),url("${formType.demo}")`,
          backgroundSize: "100px, 100px",
          backgroundPosition: " top left,bottom right",
          backgroundPositionX: "1rem, 95%",
          backgroundPositionY: "10%, 100%",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: !doneForm
            ? matchesXXL
              ? "20%"
              : tabHeight && tabWidth && tabMaxWidth
              ? "40%"
              : matchesSM
              ? "38%"
              : maxHeightTop
              ? "22%"
              : "14%"
            : matchesXXL
            ? "30%"
            : tabHeight && tabWidth && tabMaxWidth
            ? "40%"
            : matchesSM
            ? "38%"
            : maxHeightTop
            ? "22%"
            : "34%",
          left:
            tabHeight && tabWidth && tabMaxWidth
              ? "25%"
              : matchesLG
              ? "60%"
              : matchesSM
              ? "10%"
              : "55%",
          margin: matchesSM
            ? "auto"
            : tabHeight && tabWidth && tabMaxWidth && "auto",

          right:
            (tabHeight && tabWidth && tabMaxWidth && "25%") ||
            (matchesSM && "10%"),
          width: matchesXXL
            ? "450px"
            : matchesXL
            ? "30%"
            : matchesLG
            ? "33%"
            : matchesMD
            ? "41%"
            : matchesXS
            ? "80%"
            : "70%",
        }}
      >
        {!doneForm ? (
          <div className="container-fluid formBox">
            <form
              component="form"
              autoComplete="off"
              className={classes.formControl}
              onSubmit={handleSubmit}
            >
              <div className="form-head " style={{ marginBottom: "8%" }}>
                <h3
                  style={{
                    fontSize: matchesSM ? "22px" : matchesXL ? "30px" : "27px",
                  }}
                >
                  Register for free class
                </h3>
                <h6 style={{ fontSize: matchesSM ? "15px" : "20px" }}>
                  Enter your details here
                </h6>
              </div>
              <div
                className=""
                style={{
                  width: "100%",
                  height: "330px",
                  overflow: "scroll",
                  marginTop: "1%",
                }}
              >
                <TextField
                  id="standard-multiline-flexible"
                  label="Name"
                  name="name"
                  color="secondary"
                  type="text"
                  size="small"
                  margin="dense"
                  fullWidth
                  required
                  InputProps={{
                    className: classes.input,
                  }}
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  id="standard-multiline-flexible"
                  label="Email"
                  margin="dense"
                  type="email"
                  size="small"
                  required
                  fullWidth
                  name="email"
                  color="secondary"
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  value={values.email}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="standard-multiline-flexible"
                    label="Phone"
                    margin="dense"
                    error={touched.phone && Boolean(errors.phone)}
                    type="number"
                    disabled={disable}
                    name="phone"
                    required
                    size="small"
                    color="secondary"
                    fullWidth
                    variant="outlined"
                    onInput={(e) => {
                      e.target.value = parseInt(e.target.value)
                        .toString()
                        .slice(0, 10);
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <TextField
                    id="standard-multiline-flexible"
                    margin="dense"
                    type="button"
                    disabled={disable}
                    required
                    size="small"
                    color="secondary"
                    InputProps={{
                      className: classes.input,
                      style: {
                        color: "white",
                        fontSize: matchesXS ? "12px" : "",
                        padding: matchesXS ? "3%" : "0",
                      },
                    }}
                    variant="outlined"
                    value="OTP"
                    sx={{
                      backgroundColor: "purple",
                      textAlign: "center",
                      color: "white",
                      border: "1px solid lightgray",
                      width: matchesSM ? "37%" : "30%",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      if (values.phone.toString().length == 10 && !disable) {
                        setDisable(true);
                        setSendOTP(true);
                      }
                    }}
                  />
                </div>
                {touched.phone && Boolean(errors.phone) && (
                  <p
                    style={{
                      color: "#d32f2f",
                      fontWeight: "400",
                      fontSize: "0.8rem",
                      lineHeight: "1.66",
                      letterSpacing: "0.0em",
                      textAlign: "left",
                      margin: "0",
                      paddingLeft: "13px",
                    }}
                  >
                    {errors.phone}
                  </p>
                )}

                {sendOTP && (
                  <div id="recaptcha-container" className="col-4 "></div>
                )}
                {ShowOTPDisplay && (
                  <form>
                    <TextField
                      label="OTP"
                      margin="dense"
                      type="text"
                      name="otp"
                      required
                      size="small"
                      color="secondary"
                      fullWidth
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 6);
                      }}
                      InputProps={{
                        className: classes.input,
                      }}
                      value={otp}
                      onChange={(e) => {
                        console.log(e.target.value);
                        if (
                          e.target.value === "" ||
                          e.target.value.match(/^[0-9]+$/) != null
                        ) {
                          setOtp(e.target.value);
                        }
                        if (e.target.value.length === 6) {
                          verifyOtp(e);
                        }
                      }}
                      // onBlur={handleBlur}
                    />
                  </form>
                )}
                <TextField
                  label="Select Grade"
                  placeholder="Select Grade"
                  margin="dense"
                  required
                  value={values.grades}
                  onChange={(e) => {
                    handleGradeChange(e);
                    handleChange(e);
                  }}
                  id="standard-multiline-flexible"
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  select
                  name="grades"
                  defaultValue="Grade"
                  sx={{
                    textAlign: "start",
                  }}
                  error={touched.grades && Boolean(errors.grades)}
                  helperText={touched.grades && errors.grades}
                >
                  {ClassList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="standard-multiline-flexible"
                  label="Select Subject"
                  placeholder="Select Subject"
                  margin="dense"
                  required
                  name="subject"
                  value={values.subject}
                  disabled={values.grades == ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  InputProps={{
                    className: classes.input,
                  }}
                  select
                  defaultValue="Subject"
                  sx={{
                    textAlign: "start",
                  }}
                  error={touched.subject && Boolean(errors.subject)}
                  helperText={touched.subject && errors.subject}
                >
                  {subjectList.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <div className="mt-2 float-md-center float-center ">
                <Button
                  variant="contained"
                  className="btn-grad"
                  type="submit"
                  disabled={submitDisable}
                  sx={{
                    borderRadius: "25px",
                    textTransform: "none",
                    width: matchesXS ? "130px" : "150px",
                    height: matchesXS ? "45px" : "46px",
                    fontFamily: "var(--para-font)",
                    fontSize: "17px",
                    backgroundColor: "#e50b63",
                    "&.Mui-disabled": {
                      background: "#e50b63",
                      opacity: "0.5",
                      color: "white",
                    },
                  }}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <VerifiedIcon
              sx={{
                color: "green",
                fontSize: 60,
              }}
            />
            <h2 style={{ fontSize: 30, paddingTop: "10px" }}>
              Thanks for register!
            </h2>
            <p style={{ fontSize: 20 }}>
              Our team will connect with you shortly!
            </p>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                borderRadius: "20px",
                textTransform: "none",
                m: 3,
                fontFamily: "var(--para-font)",
                fontSize: "12px",
              }}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainForm;
