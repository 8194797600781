import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import { SubHeading, ParaGrey, MiniHeading } from "./../../Styles/Style";
// import "../../Styles/Dashboard.css";
import { Link, parsePath, useNavigate } from "react-router-dom";
import { useAuth, Context } from "../../Context";
import SwiperDash from "./SwiperDash";
import Pricing from "./../../Components/Pricing";
import secureLocalStorage from "react-secure-storage";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { RxDividerVertical } from "react-icons/rx";
import { Tooltip } from "@mui/material";
import {
  History,
  HomeSharp,
  Info,
  Person,
  Save,
  VideoCall,
} from "@mui/icons-material";
import BookDemo from "../../Common/BookDemo";
import AddReview from "../../Common/AddReview";
import { collection, query, where, getDoc, doc } from "firebase/firestore";
import { db } from "../../Firebase/FireBase";

const drawerWidth = 270;
function DashboardMain(props) {
  const { logOut } = useAuth();
  const navigate = useNavigate();
  const handleLogOut = async () => {
    try {
      await logOut().then(() => {
        secureLocalStorage.clear();
        navigate("/");
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [bookDemoModal, setbookDemoModal] = React.useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [userData, setUserData] = useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const fetchUser = async (userUID) => {
    const data = await getDoc(doc(db, "FrontPageForm", `${userUID}`));
    setUserData(data.data());
  };

  useEffect(() => {
    localStorage.getItem("userUID");
    const userUID = localStorage.getItem("userUID");
    fetchUser(userUID);
  }, []);

  const drawer = (
    <div>
      <div className="container mt-3">
        <Avatar
          alt="Remy Sharp"
          src={require("../../Assests/images/avatar.png")}
          sx={{ width: 80, height: 80, mx: "auto" }}
        />
        <br />

        <SubHeading className="text-center m-1">{userData?.name}</SubHeading>
        <ParaGrey className="text-center m-1">{userData?.phone}</ParaGrey>
        <ParaGrey className="text-center m-1">{userData?.email}</ParaGrey>
      </div>
      {/* <Toolbar /> */}
      <Divider />
      <List>
        {[
          "Home",
          "Book a Demo Class",
          "Book Demo Classes",
          "Review",
          "Apply for Executive",
          "Help",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() =>
                index == 1
                  ? setbookDemoModal(true)
                  : setbookDemoModal(false) || index == 3
                    ? setReviewModal(true)
                    : setReviewModal(false)
              }
            >
              <ListItemIcon>
                {index == 0 ? <HomeSharp /> : ""}
                {index == 1 ? <Save /> : ""}
                {index == 2 ? <VideoCall /> : ""}
                {index == 3 ? <History /> : ""}
                {index == 4 ? <Person /> : ""}
                {index == 5 ? <Info /> : ""}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
            {index == 1 ? (
              <BookDemo
                show={bookDemoModal}
                onHide={() => setbookDemoModal(false)}
              />
            ) : (
              ""
            )}
            {index == 3 ? (
              <AddReview
                show={reviewModal}
                onHide={() => setReviewModal(false)}
              />
            ) : (
              ""
            )}
          </ListItem>
        ))}
      </List>
      <Divider />

      {/* Logo and links */}
      <div className=" text-dark  text-center p-3">
        <Link
          onClick={handleLogOut}
          className="m-2 fw-bold"
          style={{
            color: "var( --theme-color)",
            textDecoration: "none",
          }}
        >
          Logout
        </Link>

        <MiniHeading>Connect with us on</MiniHeading>
      </div>
      <div className="d-flex gap-3 my-2 justify-content-center p-2">
        <a
          href="https://twitter.com/iskoolzofficial"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../Assests/images/twitter.png")}
            width={30}
            alt="twitter-icon"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UChK3cTG7vWYd5MGS2MsH2Sg"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../Assests/images/youtube.png")}
            width={30}
            alt="youtube-icon"
          />
        </a>
        <a
          href="https://api.whatsapp.com/send/?phone=919873008999&text=I+need+help+in+%2AISKOOLZ+APP%2A%2C+Kindly+help+me+to+resolve+my+problem.+Thank+You%21&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={require("../../Assests/images/whatsapp.png")}
            width={30}
            alt="whatsapp-icon"
          />
        </a>
        <a href="https://en-gb.facebook.com/IskoolzIndia">
          <img
            src={require("../../Assests/images/facebook.png")}
            width={30}
            alt="facebook-icon"
          />
        </a>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          py: 1,
          background: "white",
          boxShadow: "0",
          display: { lg: "none", sm: "none" },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: "-41px" }}>
            <Tooltip title="Go back">
              <Button
                size="large"
                className="buttonLogo position-fixed"
                onClick={() => navigate(-1 && "/")}
                sx={{
                  borderRadius: "50%",
                  fontSize: "20px",
                  color: "black",
                }}
              >
                <MdOutlineArrowBackIosNew />
              </Button>
            </Tooltip>
          </div>
          <div>
            <Link to="/">
              <img
                src={require("../../Assests/images/iskoolz_logo.png")}
                width={70}
                alt="iskoolz-icon"
              />
            </Link>
          </div>
          <div style={{ marginRight: "-58px" }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" }, color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox  folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box
          className="container mt-5"
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: { lg: "none", sm: "none" },
          }}
        >
          <Avatar
            alt="Remy Sharp"
            src={require("../../Assests/images/avatar.png")}
            sx={{ width: 100, height: 100, mx: "auto" }}
          />
          <br />

          <SubHeading className="text-center m-1">Gaurav</SubHeading>
          <ParaGrey className="text-center m-1">+91 999992393</ParaGrey>
          <ParaGrey className="text-center m-1">iskoolz@gmail.com</ParaGrey>
        </Box>

        <div className="container-fluid my-2">
          <SwiperDash />
        </div>

        <div className="container order-cont ">
          <img
            src={require("../../Assests/images/check-list.png")}
            className="fluid "
            width={100}
            alt=""
          />
          <ParaGrey className="text-center">No Orders</ParaGrey>
        </div>

        <div className="container ">
          <Pricing />
        </div>

        <div className="container text-center my-5">
          <SubHeading style={{ color: "#858481" }}>Join us</SubHeading>

          <div>
            <Link to="/franchise" style={{ textDecoration: "none" }}>
              <Button
                variant="text"
                type="submit"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  fontFamily: "var(--para-font)",
                  fontSize: "15px",
                  color: "var(--theme-color)",
                }}
              >
                Franchise
              </Button>
            </Link>
            <RxDividerVertical style={{ color: "#858481", fontSize: "30px" }} />{" "}
            <Link to="/franchise/:type" style={{ textDecoration: "none" }}>
              <Button
                variant="text"
                type="submit"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  fontFamily: "var(--para-font)",
                  fontSize: "15px",
                  color: "var(--theme-color)",
                }}
              >
                Subfranchise
              </Button>
            </Link>
          </div>
          <p className="text-dark">© 2022, Iskoolz.com. All rights reserved.</p>
        </div>
      </Box>
    </Box>
  );
}

DashboardMain.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DashboardMain;
