import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import styled from "styled-components";
import {
  Heading,
  StyleUl,
  HeadingPink,
  MiniHeading,
  Styleli,
  ParaGrey,
} from "../Styles/Style";

import BookDemo from "./BookDemo";

const MyContainer = styled.div`
  padding: 5px;
  height: 600px;
  @media (max-width: 500px) {
    height: auto;
  }
`;

const MyImg = styled.img`
  width: 250px;
  height: 180px;

  @media (max-width: 700px) {
    width: 140px;
    height: 100px;
  }
  @media (max-width: 520px) {
    width: 80px;
    height: 60px;
  }
`;

export default function OutlinedCard(prop) {
  const matchesXS = useMediaQuery("(max-width:576px)");

  const {
    heading,
    span,
    Subheading1,
    Subheading2,
    list1,
    list2,
    headContent,
    logo,
    minlimit,
    maxlimit,
  } = prop;

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Box sx={{ minWidth: 150, p: 0.2 }} className="p-md-3">
      <Card
        variant=""
        sx={{ boxShadow: 2, transform: "scale(0.98)" }}
        style={{ borderRadius: "20px" }}
      >
        <MyContainer>
          <CardContent className="text-start">
            <Heading className="fs-3">
              {heading}
              <HeadingPink className="d-none d-md-inline-block d-lg-inline-block">
                - {span}
              </HeadingPink>

              <HeadingPink className="d-md-none d-lg-none d-block mt-2">
                {span}
              </HeadingPink>
            </Heading>

            <ParaGrey className="py-2 text-muted">{headContent}</ParaGrey>

            <div>
              <MiniHeading>{Subheading1}</MiniHeading>
              <StyleUl>
                {list1.map((item, index) => {
                  return <Styleli key={index}>{item}</Styleli>;
                })}
              </StyleUl>
            </div>

            <div>
              <MiniHeading>{Subheading2}:</MiniHeading>
              <StyleUl>
                {list2.map((item, index) => {
                  return <Styleli key={index}> {item}</Styleli>;
                })}
              </StyleUl>
            </div>
          </CardContent>
        </MyContainer>

        <div
          className="d-flex  flex-lg-row  justify-content-between align-items-center "
          style={{ width: "100%" }}
        >
          <div className="align-self-end mb-4">
            <Button
              size="medium"
              variant="contained"
              onClick={() => setModalShow(true)}
              className="px-3 py-2 px-sm-3 py-sm-2 "
              style={{
                borderRadius: "35px",
                backgroundColor: "var(--theme-color)",
                marginLeft: "15px",
                textTransform: "none",
                // padding: "8px 13px",
                fontSize: matchesXS ? "10px" : "15px",
                fontFamily: "var(--heading-font)",
                position: "relative",
              }}
            >
              Book Demo
            </Button>
          </div>
          <div className="pb-lg-0 mb-2">
            <MyImg
              src={logo}
              className="img-fluid"
              alt="shine_icon"
              loading="lazy"
            />
          </div>
        </div>
        <BookDemo
          show={modalShow}
          showProp={setModalShow}
          onHide={() => setModalShow(false)}
          maxlimit={maxlimit}
          minlimit={minlimit}
        />
      </Card>
    </Box>
  );
}
