export const FranchiseData = [
    {
      logo: require("../Assests/webimg/img1.png"),
      heading: " 100% Business Assistance",
      para: ` We provide 100% business assistance with an extensive training program based on the business strategies required for owning and operating a successful Online School. `,
    },
    {
      logo: require("../Assests/webimg/img2.png"),
      heading: "Iskoolz Recognition",
      para: ` You are going to be a partner with a recognized brand, people will automatically know what Iskoolz is, what you provide.`,
    },
    {
      logo: require("../Assests/webimg/img3.png"),
      heading: "Marketing Support",
      para: "Iskoolz provides Digital Marketing, Online Website, Banners and Flairs with Graphic Support, and Social media advertisement that helps to generate more leads.",
    },
    {
      logo: require("../Assests/webimg/img4.png"),
      heading: "Become a Partner of Upcoming Education Future",
      para: `Iskoolz is based on a future Education model that completely fulfills all the demands of technology-focused classrooms. Students get the learning experience to meet their interests, time constraints, and academic needs.`,
    },
    {
      logo: require("../Assests/webimg/img4.png"),
      heading: "A Profitable Investment",
      para: `Online Education in India is necessary for all students and students are also enthusiastic about this new concept of education as they get the best education that is LIVE and interactive Classes, Animated Videos, and Coding and Technical Education.`,
    },
  ];
