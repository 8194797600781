import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Heading, SubHeading } from "./../Styles/Style";
import { ClassList } from "../Services/BookDemo";
import { bookDemoData, reviewSend } from "../Pages/allApi";
import {
  TextField,
  Button,
  FormControl,
  ThemeProvider,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { theme } from "../Styles/Theme";
import { useStyles } from "../Styles/Theme";
import { useAuth } from "../Context";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../Firebase/FireBase";
import dataJson from "../Services/CitiesData.json";
import { toast } from "react-toastify";
import { set } from "firebase/database";

function AddReview(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setUpRecaptcha, loading, setloading, Spinner } = useAuth();
  const initialValues = {
    name: "",
    phoneNumber: "",
    state: "",
    districts: "",
    review: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [confirmObj, setConfirmObj] = useState("");
  const [showDisplay, setShowDisplay] = useState(false);
  const [disable, setDisable] = useState("");
  const [otpSubmit, setOtpSubmit] = useState(false);
  const [showSubject, setShowSubject] = useState(false);
  const [districts, setDistricts] = useState([]);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    dataJson.states.filter((x) => {
      if (x.state === e.target.value) {
        setDistricts(x.districts);
        setShowSubject(e.target.value !== "" ? true : false);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const er = validateForm(formValues);
    console.log(er);
    if (Object.keys(er).length == 0) {
      setIsSubmit(true); // for Submission
      setDisable(true); //for button disable
    }
  };

  const err = {};
  const validateForm = (formValues) => {
    const { name, phoneNumber, state, districts, review } = formValues;

    if (!name) {
      err.name = "Enter your name";
    }
    if (phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      err.phoneNumber = "Phone must be valid and contain 10 digits";
    }
    if (!state) {
      err.state = "Please select a state ";
    }
    if (!districts) {
      err.districts = "Please select your districts ";
    }
    if (!review) {
      err.review = "Please write your review ";
    }
    setErrors(err);
    return err;
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 || isSubmit === true) {
      const sendData = async () => {
        const number = formValues.phoneNumber;
        try {
          const phone = "+91" + number;
          const res = await setUpRecaptcha(phone);
          setConfirmObj(res);
          console.log(res, "======>>>>res");
          setShowDisplay(true);
          setDisable(false);
        } catch (error) {
          if (error.code == "auth/too-many-requests") {
            toast.error("Too many requests. Try again after few hours!!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmit(false);
            setFormValues({ ...formValues, phoneNumber: "" });
          } else {
            setIsSubmit(false);
            setFormValues({ ...formValues, phoneNumber: "" });
          }
          console.error(error);
          setDisable(false);
        }
      };
      sendData();
    }
  }, [errors, isSubmit]);

  const verifyOtp = async (e) => {
    setloading(true);
    e.preventDefault();

    if (!otp) {
      setErrors((prevErr) => ({
        ...prevErr,
        otp: "Please enter the correct OTP",
      }));
      setloading(false);
      return;
    }

    try {
      // await confirmObj.confirm(otp);
      // secureLocalStorage.setItem("login", true);
      setOtpSubmit(true);
      await fetchData();
      // navigate("/dashboard");

      setOtp("");
      console.log(formValues);
    } catch (err) {
      if (err.code == "auth/invalid-verification-code") {
        toast.error("Wrong Otp!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (err.code == "auth/code-expired") {
        toast.error("Otp Expired!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false);
        setShowDisplay(false);
        setFormValues({ ...formValues, phoneNumber: "" });
      }

      setOtp("");

      // setErrors((prevErr) => ({
      //   ...prevErr,
      //   otp: `Enter the correct OTP ${message}`,
      // }));
    }
    setloading(false);
  };

  const fetchData = async () => {
    console.log("fetch data call", formValues);
    const reviewData = await reviewSend(formValues);
    console.log(reviewData);
    if (reviewData.status == 200) {
      toast.success("Review submited.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastClassName: "custom-toast",
      });
      setFormValues(initialValues);
      setIsSubmit(false);
      setShowDisplay(false);
    } else {
      toast.error("Something went wrong. Try Again!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormValues(initialValues);
      setIsSubmit(false);
      setShowDisplay(false);
    }
    setDisable(false);
  };

  const ModalClose = props.onHide;
  const close_fun = () => {
    ModalClose();
  };

  return (
    <Modal
      {...props}
      size="md"
      height={80}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Spinner />
      <div className="text-center py-3">
        <i
          className="fa-sharp fa-solid fa-xmark  "
          onClick={close_fun}
          style={{
            float: "right",
            cursor: "pointer",
            position: "relative",
            top: "-10px",
            right: "5px",
          }}
        ></i>
        <Heading>Review</Heading>
        <SubHeading>Share your review here</SubHeading>
      </div>
      <ThemeProvider theme={theme}>
        {showDisplay ? (
          <form onSubmit={verifyOtp}>
            <div className="px-2 mx-3">
              <TextField
                inputProps={{ maxLength: 6 }}
                id="filled-basic"
                fullWidth
                InputProps={{
                  className: classes.input,
                }}
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
                type="text"
                name="otp"
                color="primary"
                label="Enter OTP"
                autoFocus={true}
                variant="standard"
                placeholder="Enter OTP"
              />
            </div>
            <div className="float-end">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  m: 3,
                  fontFamily: "var(--para-font)",
                  fontSize: "12px",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit} className={classes.formControl}>
            <p className="text-danger">{errors.base}</p>
            <div className="px-2 mx-3">
              <TextField
                id="standard-multiline-flexible"
                label="Your name"
                name="name"
                color="secondary"
                type="text"
                margin="normal"
                fullWidth
                variant="standard"
                InputProps={{
                  className: classes.input,
                }}
                sx={{
                  my: 2,
                }}
                onChange={handleChange}
                value={formValues.name}
                // error={touched.name && Boolean(errors.name)}
                error={errors.name}
                helperText={errors.name}
              />
            </div>

            <div className="px-2 mx-3">
              <FormControl
                variant="standard"
                fullWidth
                color="secondary"
                margin="normal"
                helperText="Asfasfsadfsdfsdfsdfsd"
                // helperText={touched.grade && errors.grade}
                sx={{ marginBottom: "0" }}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Select State
                </InputLabel>
                <Select
                  value={formValues.grade}
                  fullWidth
                  onChange={handleChange}
                  label="Select State"
                  name="state"
                  helperText="Asfasfsadfsdfsdfsdfsd"
                >
                  <MenuItem value=" " disabled={true}>
                    <em>Select State</em>
                  </MenuItem>
                  {dataJson.states.map((item, i) => {
                    return (
                      <MenuItem key={item.state} value={item.state}>
                        <span> {item.state} </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {errors.state && (
                <p
                  style={{
                    color: "#d32f2f",

                    fontWeight: "400",
                    fontSize: "0.85rem",
                    lineHeight: "1.66",
                    letterSpacing: "0.0em",
                    textAlign: "left",

                    margin: "0",
                  }}
                >
                  {errors.state}
                </p>
              )}
            </div>

            {/*Sel sub  */}
            <div className="px-2 mx-3">
              {showSubject ? (
                <FormControl
                  className={classes.formControl}
                  variant="standard"
                  fullWidth
                  color="secondary"
                  margin="normal"
                >
                  <InputLabel id="districts-label">Select Districts</InputLabel>
                  <Select
                    labelId="districts-label"
                    id="districts"
                    name="districts"
                    value={formValues.districts}
                    onChange={handleChange}
                    error={Boolean(errors.districts)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {districts.map((item, i) => {
                      return (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>

                  {errors.districts && (
                    <p
                      style={{
                        color: "#d32f2f",

                        fontWeight: "400",
                        fontSize: "0.85rem",
                        lineHeight: "1.66",
                        letterSpacing: "0.0em",
                        textAlign: "left",

                        margin: "0",
                      }}
                    >
                      {errors.districts}
                    </p>
                  )}
                </FormControl>
              ) : null}
            </div>

            <div className="px-2 mx-3">
              <TextField
                id="standard-multiline-flexible"
                label="Enter your phone number"
                name="phoneNumber"
                color="secondary"
                margin="normal"
                type="number"
                fullWidth
                variant="standard"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                InputProps={{
                  className: classes.input,
                }}
                value={formValues.phoneNumber}
                sx={{
                  my: 2,
                }}
                onChange={handleChange}
                helperText={errors.phoneNumber}
                error={errors.phoneNumber}
              />
            </div>

            <div className="px-2 mx-3">
              <TextField
                id="standard-multiline-flexible"
                label="Write Review here"
                name="review"
                color="secondary"
                type="text"
                margin="normal"
                fullWidth
                variant="standard"
                InputProps={{
                  className: classes.input,
                }}
                sx={{
                  my: 2,
                }}
                onChange={handleChange}
                value={formValues.review}
                error={errors.review}
                helperText={errors.review}
              />
            </div>

            {isSubmit && (
              <div id="recaptcha-container" className="col-7 "></div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  m: 3,
                  fontFamily: "var(--para-font)",
                  fontSize: "12px",
                  width: "28%",
                  padding: "10px 0",
                }}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </ThemeProvider>
    </Modal>
  );
}

export default AddReview;
