/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { PricingData } from "../Services/PricingData";
import { FcCheckmark } from "react-icons/fc";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Heading,
  StyleUl,
  HeadingPink,
  ParaGraph,
  MiniHeading,
  Styleli2,
} from "../Styles/Style";
import { FaRupeeSign } from "react-icons/fa";
import CustomizedAccordions from "../Common/Accordion";
import useMediaQuery from "@mui/material/useMediaQuery";

function Pricing() {
  const matchesXXL = useMediaQuery("(min-width:1400px)");
  const matchesXL = useMediaQuery("(min-width:1200px)");
  const matchesLG = useMediaQuery("(min-width:992px)");
  const matchesXS = useMediaQuery("(max-width:400px)");

  return (
    <div
      id="pricing"
      className="pt-2"
      style={{ border: "0px solid red", width: "100%" }}
    >
      <div
        className="container-xl mt-5"
        style={{
          padding: matchesXL ? "0" : matchesLG ? "0%" : "0%",
          margin: "auto",
          padding: "0%",
          border: "0px solid red",
          width: "100%",
        }}
      >
        <div>
          <Heading className="pt-3 text-center">Pricing & Plans</Heading>
          <ParaGraph className="text-center">
            Now access the best high-content at the most affordable price
          </ParaGraph>
        </div>
        <div
          className="row row-lg-5 mt-5"
          style={{
            border: "0px solid green",
            margin: matchesXS ? "0%" : "auto",
            width: "100%",
          }}
        >
          {PricingData.map((item, index) => {
            return (
              <div
                className="col-lg-4 col-12"
                style={{
                  border: "0px solid blue",
                  marginLeft: "0",
                }}
                key={index}
              >
                <div
                  className="justify-content-center w-full d-flex"
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="card rounded-3 shadow-sm  d-sm-none d-none d-md-none d-lg-flex  "
                    style={{
                      width: "100%",
                      height: "670px",
                      border: `2px Solid ${item.color}`,
                    }}
                  >
                    <div
                      className="card-header text-start "
                      style={{
                        background: `${item.color}`,
                        height: "8rem",
                        border: "0px solid blue",
                        textAlign: "center",
                      }}
                    >
                      <HeadingPink
                        className=""
                        style={{
                          fontSize: matchesXXL ? "20px" : "17px",
                          textAlign: "center",
                        }}
                      >
                        {item.title}
                      </HeadingPink>
                      <ParaGraph className="my-2 "> Hurry Up </ParaGraph>
                      <ParaGraph className="fw-light">
                        <HeadingPink style={{ fontSize: "18px" }}>
                          <FaRupeeSign className="fs-6 mb-1" />
                          {item.price1} /
                        </HeadingPink>
                        <small> Year</small>
                        <br />
                        {/* {item.price2 === "" ? (
                          ""
                        ) : (
                          <>
                            <HeadingPink style={{ fontSize: "16px" }}>
                              <FaRupeeSign className="fs-6 mb-1" />
                              {item.price2} /
                            </HeadingPink>
                            <small> 3 Years</small>
                          </>
                        )} */}
                      </ParaGraph>
                    </div>
                    <div className="card-body">
                      <MiniHeading className="card-title pricing-card-title text-start">
                        <small>What we offer:</small>
                      </MiniHeading>
                      <ul style={{ lineHeight: 3, padding: 0 }}>
                        {item.offer.map((data, index) => {
                          return (
                            <>
                              <Styleli2 key={index}>
                                <div className="row">
                                  <div className="col-1">
                                    <FcCheckmark />
                                  </div>
                                  <div className="col text-start">{data}</div>
                                </div>
                              </Styleli2>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className="d-lg-none col-12 px-0 py-2"
                  style={{
                    width: "100%",
                    margin: "0%",
                    padding: "0%",
                  }}
                >
                  <CustomizedAccordions
                    // Sending Data through Props
                    color={item.color}
                    title={item.title}
                    price1={item.price1}
                    price2={item.price2}
                    offer={item.offer}
                    index={item.index}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
