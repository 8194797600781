import React from "react";
import { MiniHeading } from "../Styles/Style";
import "../Styles/Footer.css";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useContext } from "react";
import { Context } from "../Context";
function Footer() {
  const { isMobile } = useContext(Context);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="container-fluid m-0 mainDiv">
        <footer className="row row-cols-2 row-cols-sm-2 row-cols-md-4 mx-lg-5 mx-md-1 px-4 pt-5 ">
          <div className="col d-none d-lg-block text-lg-start text-md-start  d-md-block">
            <a href="/">
              <img
                src={require("../Assests/images/iskoolz_logo.png")}
                width={100}
                style={{ zIndex: 0 }}
                alt="iskoolz-icon"
              />
            </a>

            <div className="d-flex gap-3 my-2 ">
              <Tooltip title="twitter">
                <a
                  href="https://twitter.com/iskoolzofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../Assests/images/twitter.png")}
                    width={30}
                    alt="twitter-icon"
                  />
                </a>
              </Tooltip>
              <Tooltip title="Youtube">
                <a
                  href="https://www.youtube.com/channel/UChK3cTG7vWYd5MGS2MsH2Sg"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../Assests/images/youtube.png")}
                    width={30}
                    alt="youtube-icon"
                  />
                </a>
              </Tooltip>
              <Tooltip title="Whatsapp">
                <a
                  href="https://api.whatsapp.com/send/?phone=919873008999&text=I+need+help+in+%2AISKOOLZ+APP%2A%2C+Kindly+help+me+to+resolve+my+problem.+Thank+You%21&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../Assests/images/whatsapp.png")}
                    width={30}
                    alt="whatsapp-icon"
                  />
                </a>
              </Tooltip>
              <Tooltip title="Facebook">
                <a
                  href="https://en-gb.facebook.com/IskoolzIndia"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../Assests/images/facebook.png")}
                    width={30}
                    alt="facebook-icon"
                  />
                </a>
              </Tooltip>
            </div>
            <p className="navigation">
              © 2022, Iskoolz.com. All rights reserved.
            </p>
          </div>

          <div className="col mb-3 col-md-3 text-md-start text-lg-start col-7 text-light">
            <MiniHeading>QUICK LINKS</MiniHeading>
            <ul className="nav flex-column contact-list ">
              <li className="nav-item mb-1">
                <HashLink to="/franchise#top" className="navigation" smooth>
                  Franchise
                </HashLink>
              </li>
              <li className="nav-item mb-1">
                <HashLink to="/franchise/Sub#top" className="navigation" smooth>
                  Subfranchise
                </HashLink>
              </li>
            </ul>
          </div>

          <div className="col mb-3 text-light col-md-3 text-md-start text-lg-start col-5">
            <MiniHeading>CONTACT US</MiniHeading>
            <ul className="nav flex-column contact-list">
              <li className="nav-item mb-1">
                <HashLink to="#contact-home" smooth className="navigation ">
                  Contact
                </HashLink>
              </li>
              <li className="nav-item mb-1">
                <Link to="/faq" className="navigation" onClick={scrollToTop}>
                  FAQ's
                </Link>
              </li>
              <li className="nav-item mb-1">
                <Link to="/terms" className="navigation">
                  Terms
                </Link>
              </li>
              <li className="nav-item mb-1">
                <Link to="/privacy" className="navigation">
                  Privacy
                </Link>
              </li>
            </ul>
          </div>

          <div className="col mb-3 text-white text-md-start text-lg-start text-sm-start  text-start col-12 col-md-3 col-sm-12 ">
            <MiniHeading className="text-center mb-md-2 mb-sm-4">
              CONNECT WITH US
            </MiniHeading>
            <ul className="nav flex-column">
              <li className="nav-item mb-3 navigation pt-2 ">
                <div className="row">
                  <div className="col-2">
                    <IoMdCall size={"25px"} />
                  </div>
                  <div className="col-10 text-sm-start text-start">
                    Call us (Mon-Sun: 9AM - 6PM) <br />
                    +91 9873008999
                  </div>
                </div>
              </li>

              <li className="nav-item  navigation">
                <div className="row">
                  <div className="col-2">
                    <IoMdMail size={"25px"} />
                  </div>
                  <div className="col-10 text-sm-start text-start">
                    Email us on <br />
                    info@iskoolz.com
                  </div>
                </div>
              </li>
              {isMobile ? (
                <li
                  className="navigation nav-item  text-center text-md-start text-lg-start "
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    paddingTop: "3rem",
                    paddingLeft: "12px",
                  }}
                >
                  Get it on
                </li>
              ) : (
                <li
                  className="navigation nav-item  text-center text-md-start text-lg-start "
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    paddingTop: "2rem",

                    paddingLeft: "15px",
                  }}
                >
                  Get it on
                </li>
              )}

              <li
                className="navigation nav-item  text-center text-md-start text-lg-start"
                style={{ paddingLeft: "19px" }}
              >
                <img
                  src={require("../Assests/images/img.webp")}
                  className="img-fluid py-3"
                  alt="shine_icon"
                  width="140"
                  loading="lazy"
                  style={{ marginLeft: "-8px" }}
                />
              </li>
            </ul>
          </div>

          <div className="col-12 mb-3 text-white  d-md-none d-lg-none text-center mx-auto">
            <MiniHeading className="text-center">
              Connect with us on
            </MiniHeading>
            <div className="d-flex gap-3 my-2 justify-content-center p-2">
              <a
                href="https://twitter.com/iskoolzofficial"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../Assests/images/twitter.png")}
                  width={30}
                  alt="twitter-icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UChK3cTG7vWYd5MGS2MsH2Sg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../Assests/images/youtube.png")}
                  width={30}
                  alt="youtube-icon"
                />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=919873008999&text=I+need+help+in+%2AISKOOLZ+APP%2A%2C+Kindly+help+me+to+resolve+my+problem.+Thank+You%21&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={require("../Assests/images/whatsapp.png")}
                  width={30}
                  alt="whatsapp-icon"
                />
              </a>
              <a href="https://en-gb.facebook.com/IskoolzIndia">
                <img
                  src={require("../Assests/images/facebook.png")}
                  width={30}
                  alt="facebook-icon"
                />
              </a>
            </div>
            <p className="navigation">
              © 2022, Iskoolz.com. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
