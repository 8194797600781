import React from 'react'
import CommentSwiper from '../Common/CommentSwiper'
import { Heading, ParaGraph } from "../Styles/Style";


const CommenSwipwer = () => {
  return (

    <div className="container-fluid text-center mb-5 ">
      <Heading className="">
        Why do parents love <br /> iskoolz classes?
      </Heading>

      <div className="container-fluid p-0 px-md-5">
        <CommentSwiper />
      </div>
    </div>
  )
}

export default CommenSwipwer