import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import "../../Styles/FAQ.css";
import { FaQ } from "./../../Services/Data";
import { Heading, ParaGraph, ParaGrey, SubHeading } from "./../../Styles/Style";
import Footer from "./../../Components/Footer";
import { AppBar, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { MdOutlineArrowBackIosNew } from "react-icons/md";


const FAQ = () => {
  const navigate = useNavigate();
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row",

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
      color: "var(--theme-color)",
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      marginBottom: "15px",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <>
    
      <AppBar
        component="nav"
        sx={{ bgcolor: "white", boxShadow: 0, mb: 6, p: 0, ml: 0 }}
      >
        <Toolbar>
          <Tooltip title="Go back">
            <Button
              size="medium"
              className="buttonLogo position-fixed"
              onClick={() => navigate(-1 && "/")}
              sx={{
                borderRadius: "50%",
                fontSize: "25px",
                marginTop: "-18px",
                marginLeft: "-10px",

                height: "50px",
              }}
            >
              <MdOutlineArrowBackIosNew className="text-dark" />
            </Button>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <div className="container mt-5 ">
        <div className=" p-4">
          <ParaGraph className="heading text-center fs-2 fw-bold">
            Frequently Asked Questions
          </ParaGraph>
        </div>

        <div className="container p-md-3 mainCont">
          {FaQ.map((item, index) => {
            return (
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                className="border-0 "
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ParaGraph
                    style={{
                      color: expanded === index ? "var(--theme-color)" : "",
                      fontSize: "17px",
                     
                    }}
                  >
                    {item.faq}
                  </ParaGraph>
                </AccordionSummary>
                <AccordionDetails>
                  <ParaGrey>{item.faqAns}</ParaGrey>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>

      <div className="mt-5">
        {/* Footer added */}
        <Footer />
      </div>
    </>
  );
};

export default FAQ;
