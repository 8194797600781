import React, { useContext, useEffect } from "react";
import "../Styles/Card.css"
import { ThemeProvider } from "@mui/material/styles";
import { MiniHeading } from "../Styles/Style";
import { theme } from './../Styles/Theme';
import Avatar from '@mui/material/Avatar';
import { ParaGrey } from './../Styles/Style';
import { Context } from "../Context";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Card(props) {
   const  {title,para ,para1 , para2,para3,para4, logo ,btn}=  props;
   const { isMobile,handleResize } = useContext(Context);

   useEffect(()=>{
    handleResize()
  
 },[isMobile])
  return (
  <ThemeProvider theme={theme}>
    <div className="col d-flex align-items-start text-justify flex" >
    {/* for mobile view */}
      {isMobile? <div className="icon-square d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 p-md-4">
                 { logo?  <img src={logo} width="60" height="60" alt="icons" />:
                  <Avatar sx={{backgroundColor:"var(--theme-color)", width: 40, height: 40 ,fontSize:"15px" ,fontWeight:"bold", }} >{btn}</Avatar>}
                 
                </div>:
              //for desktop view:
                  <div className="icon-square d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 px-4 ">
                 { logo?  <img src={logo} width="70" height="70" alt="icons-desktop-view" />:
                  <Avatar sx={{backgroundColor:"var(--theme-color)", width: 40, height: 40 ,fontSize:"15px" ,fontWeight:"bold", }} >{btn}</Avatar>}
                </div>}

                <div className="p-2" >
                  <MiniHeading className=" mb-3 text-start">{title}</MiniHeading>
                  <ParaGrey className="text-start">{para} </ParaGrey>
                  <ParaGrey className="text-start mb-2 ">{para1}</ParaGrey>
                  <ParaGrey className="text-start mb-2">{para2}</ParaGrey>
                  <ParaGrey className="text-start mb-2">{para3}</ParaGrey>
                  <ParaGrey className="text-start">{para4} </ParaGrey>
                </div>
  </div>
  </ThemeProvider>
  );
}

export default Card;
