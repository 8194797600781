import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Protected(props) {
  const naviagte = useNavigate();
  const { Comp } = props;

  useEffect(() => {
    const login = localStorage.getItem("@secure.b.login");

    if (!login) {
      naviagte("/");
    }
  }, []);

  return (
    <div>
      <Comp />
    </div>
  );
}

export default Protected;
